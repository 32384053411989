<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      :key-expr="['locNm', 'custNm', 'itemCode']"
      @toolbar-preparing="onToolbarPreparing($event)"
      @rowDblClick="showModify($event)"
      @cell-prepared="onCellPrepared">
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multiple" />
      <DxExport :enabled="true" />
      <DxColumn data-field="locNm" caption="Location" />
      <DxColumn data-field="custNm" caption="Cust Name" />
      <DxColumn data-field="itemCode" caption="Item Code" />
      <DxColumn data-field="itemName" caption="Item Name" />
      <DxColumn data-field="salesPrice" caption="Sales Price(KRW)" data-type="number"/>
      <DxColumn data-field="userName" caption="Update User" />
      <DxColumn data-field="updateTime" caption="Update Time" />
      <DxColumn data-field="slipNo" caption="Slip No" />
    </DxDataGrid>
     <DxPopup
      :ref="popupRefName"
      width="820"
      height="auto"
      :title="popupTitle"
      :drag-enabled="true"
      :hideOnOutsideClick="true"
      :show-title="true"
    >
      <div class="content-block dx-card responsive-paddings">
        <DxForm
          :ref="modifyDataRefName"
          label-location="left"
          :form-data="formDataModify"
        >
          <DxSimpleItem
            data-field="locNm"
            editor-type="dxSelectBox" 
            :editor-options="{
              items: ['KOR Warehouse', 'HK Warehouse', 'WD Warehouse', 'ZQ Warehouse', 'TH Warehouse'],
              disabled: updateFlag}">
            <DxLabel text="Location" />
            <DxRequiredRule />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="custNm"
            editor-type="dxSelectBox"
            :editor-options="{
            items: ['COILMASTER(KOR)', 'COILMASTER(HK)', 'COILMASTER(EU)', 'COILMASTER(WD)', 'COILMASTER(ZQ)', 'COILMASTER(CHEM)','COILMASTER(POL)'],
            disabled: updateFlag}">
            <DxLabel text="Cust Name" />
            <DxRequiredRule />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="itemCode"
            editor-type="dxSelectBox"
            :editor-options="{
              dataSource: itemNameCodeList,
              valueExpr: 'itemCode',
              displayExpr: 'itemNameCode',
              searchEnabled: true,
              disabled: updateFlag
            }"
          >
            <DxLabel text="Item" />
            <DxRequiredRule />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="salesPrice"
            editor-type="dxNumberBox"
            :editor-options="{
              min: 0,
              max: 9999999}"
          >
            <DxLabel text="Sales Price" />
            <DxRequiredRule />
          </DxSimpleItem>
         
          <DxGroupItem :colCount="updateFlag ? 2 : 1">
            <DxButtonItem
              :horizontal-alignment="updateFlag ? 'right' : 'center'"
              vertical-alignment="center"
              :buttonOptions="{
                text: 'Save',
                icon: 'save',
                onClick: saveFactorySalesPrice,
              }"
            />

            <DxButtonItem
              v-if="updateFlag"
              horizontal-alignment="left"
              vertical-alignment="center"
              :buttonOptions="{
                text: 'Delete',
                icon: 'clear',
                onClick: deleteFactorySalesPrice,
              }"
            />
          </DxGroupItem>
        </DxForm>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxExport,
  DxHeaderFilter,
  DxSearchPanel
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import {
  DxForm,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxRequiredRule,
  DxGroupItem,
} from "devextreme-vue/form";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxSearchPanel,
    DxPopup,
    DxForm,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxRequiredRule,
    DxGroupItem
  },
  data() {
    return {
      gridRefName: "grid",
      dataSource: [],
      popupRefName: "popup",
      modifyDataRefName: "modifyData",
      formDataModify: {},
      itemNameCodeList: [],
      newModeFlag: false,
      popupTitle:'',
    };
  },
  mounted() {
    dsu.getAxiosItemNameCodeList().then((res) => {
      this.itemNameCodeList = res.data.data;
    });
    this.refreshData();
  },
  computed: {
    grid: function () { return this.$refs[this.gridRefName].instance; },
    popup: function () { return this.$refs[this.popupRefName].instance; },
    form: function(){ return this.$refs[this.modifyDataRefName].instance; }
  },
  methods: {
    onCellPrepared(e) {
      if (e.rowType == "header") {
        e.cellElement.bgColor = "#E4EEF7";
      } 
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: "after",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            text: "New Factory Sales Price(Custom)",
            icon: "info",
            stylingMode: "outlined",
            onClick: this.showNew.bind(this),
          },
        },
      );
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    refreshData() {
      this.grid.beginCustomLoading("Data Loading...");
      dsu.getAxiosOBJGet("/api/MFSP/selectFactorySalesPrice").then((res) => {
          this.grid.endCustomLoading();
          this.dataSource = res.data.data;
      });
    },
    showNew() {
      this.popupTitle = 'New Factory Sales Price(Custom)';
      this.formDataModify = {};
      this.updateFlag = false;
      this.popup.show();
    },
    showModify(e) {
      this.popupTitle = 'Modify Factory Sales Price(Custom)';
      this.formDataModify = JSON.parse(JSON.stringify(e.data));
      this.updateFlag = true;
      this.popup.show();
    },
    saveFactorySalesPrice(e) {
      let validationResult = this.form.validate();
      if (validationResult.status != "valid") {
          return;
      }

      e.component.option('disabled', true);
      dsu.getAxiosOBJPost('/api/MFSP/updateFactorySalesPriceModify', this.formDataModify).then(res => {
        e.component.option('disabled', false);
        let response = res.data;
        this.refreshData();
        if(response.result == 'SUCCESS'){
          fn.notifySuccess(response.msg);
          this.popup.hide();
        }else {
          fn.notifyError(response.msg);
          e.cancel = true;
        }
      });
    },
    async deleteFactorySalesPrice(e) {
      if ((await fn.confirm("Are you sure you want to delete?")) == false) {
        return;
      }
      e.component.option('disabled', true);
      dsu.getAxiosOBJPost('/api/MFSP/deleteFactorySalesPriceModify', this.formDataModify).then(res => {
        e.component.option('disabled', false);
        let response = res.data;
        this.refreshData();
        if(response.result == 'SUCCESS'){
          fn.notifySuccess(response.msg);
          this.popup.hide();
        }else {
          fn.notifyError(response.msg);
          e.cancel = true;
        }
      });
    }
  },
};
</script>

<style scoped>
  #grid {
    max-height: calc(100vh - 140px);
  }
</style>
