<template>
  <header class="header-component">
    <dx-toolbar class="header-toolbar">
      <dx-item
        :visible="menuToggleEnabled"
        location="before"
        css-class="menu-button"
      >
        <template #default>
          <dx-button
            icon="menu"
            styling-mode="text"
            @click="toggleMenuFunc"
          />
        </template>
      </dx-item>

      <dx-item
        location="before"
        css-class="transName"
      >
        <template #default>
            <div class='description'>{{transName}} </div> 
        </template>
      </dx-item>

      <dx-item
        location="after"
        locate-in-menu="auto">
      <template #default>
          <div>
            <dx-button
              class="user-button authorization"
              :width="210"
              height="100%"
              styling-mode="text"
            >
              <user-panel :user="user" :menu-items="userMenuItems" menu-mode="context" />
            </dx-button>
          </div>
        </template>
      </dx-item>

    </dx-toolbar>
  </header>
</template>

<script>
import DxButton from "devextreme-vue/button";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import auth from "../auth";
import { ref } from 'vue';
import UserPanel from "./user-panel";
import { useRouter } from 'vue-router'; 

export default {
  props: {
    menuToggleEnabled: Boolean,
    title: String,
    toggleMenuFunc: Function,
    logOutFunc: Function,
    transName: String
  },
  created() {
    auth.getUser().then((e) => this.user = e.data);
  },
  setup() {
    const router = useRouter();
    let user = ref({});

    const userMenuItems = [
        {
          text: "Change Password",
          icon: "key",
          onClick: onChangePasswordClick
        },
        {
          text: "Logout",
          icon: "runner",
          onClick: onLogoutClick
        },
        {
          text: "Version : 0.1",
          icon: "info",
        }
    ];
      
    function onLogoutClick() {    
      auth.logOut();
    }

    function onChangePasswordClick() {
      if(router.currentRoute.path != '/change-password'){
        router.push({
          path: "/change-password"
        });
      }
    }
    
    return {
      user,
      userMenuItems
      
    };
  },
  components: {
    DxButton,
    DxToolbar,
    DxItem,
    UserPanel
  }
};
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";
@import "../dx-styles.scss";

.header-component {
  flex: 0 0 auto;
  z-index: 999;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);

  .dx-toolbar .dx-toolbar-item.menu-button > .dx-toolbar-item-content .dx-icon {
    color: $base-accent;
  }
}

.dx-toolbar.header-toolbar .dx-toolbar-items-container .dx-toolbar-after {
  padding: 0 40px;

  .screen-x-small & {
    padding: 0 20px;
  }
}

.dx-toolbar .dx-toolbar-item.dx-toolbar-button.menu-button {
  width: $side-panel-min-width;
  text-align: center;
  padding: 0;
}

.header-title .dx-item-content {
  padding: 0;
  margin: 0;
}

.transName
{
  width: auto;
  .description
  {
    padding-left: 20px;
    font-size: 20px;
    font-weight: 700;
  }
}

.dx-theme-generic {
  .dx-toolbar {
    padding: 10px 0;
  }

  .user-button > .dx-button-content {
    padding: 3px;
  }
}
</style>
