<template>
  <div v-show="visibleSearchCondition" class="content-block dx-card responsive-paddings">
    <DxForm :ref="searchFormRefName" label-location="left" :form-data="formData">
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />
      <DxSimpleItem data-field="custName" editorType="dxTagBox" :editor-options="{
        items: customerInfo,
        showSelectionControls: true,
        maxDisplayedTags: 1,
        valueExpr: 'name',
        displayExpr: 'name',
        searchEnabled: true
      }">
        <DxLabel text="CUST_NAME" />
      </DxSimpleItem>

      <DxSimpleItem data-field="itemName" editor-type="dxTagBox" :editor-options="{
        dataSource: itemNameList,
        showSelectionControls: true,
        maxDisplayedTags: 1,
        valueExpr: 'itemName',
        displayExpr: 'itemName',
        searchEnabled: true
      }">
        <DxLabel text="ITEM_NAME" />
      </DxSimpleItem>

      <DxSimpleItem data-field="location" editor-type="dxTagBox" :editor-options="{
        dataSource: locationList,
        showSelectionControls: true,
        maxDisplayedTags: 1,
        valueExpr: 'ID',
        displayExpr: 'Name',
        searchEnabled: true
      }">
        <DxLabel text="LOCATION" />
      </DxSimpleItem>

      <DxSimpleItem data-field="group2Name" editor-type="dxTagBox" :editor-options="{
        dataSource: group2NameList,
        showSelectionControls: true,
        maxDisplayedTags: 1,
        valueExpr: 'group2Name',
        displayExpr: 'group2Name',
        searchEnabled: true
      }">
        <DxLabel text="GROUP_2_NAME" />
      </DxSimpleItem>

      <DxSimpleItem data-field="salesPerson" editor-type="dxTagBox" :editor-options="{
        dataSource: salesPersonList,
        showSelectionControls: true,
        maxDisplayedTags: 1,
        valueExpr: 'salesPerson',
        displayExpr: 'salesPerson',
        searchEnabled: true
      }">
        <DxLabel text="SALES_PERSON" />
      </DxSimpleItem>

      <DxSimpleItem data-field="application" editor-type="dxTagBox" :editor-options="{
        dataSource: applicationList,
        showSelectionControls: true,
        maxDisplayedTags: 1,
        valueExpr: 'application',
        displayExpr: 'application',
        searchEnabled: true
      }">
        <DxLabel text="APPLICATION" />
      </DxSimpleItem>

      <DxSimpleItem data-field="product" editor-type="dxTagBox" :editor-options="{
        dataSource: productList,
        showSelectionControls: true,
        maxDisplayedTags: 1,
        valueExpr: 'product',
        displayExpr: 'product',
        searchEnabled: true
      }">
        <DxLabel text="PRODUCT" />
      </DxSimpleItem>

      <DxButtonItem horizontal-alignment="center" vertical-alignment="center" :buttonOptions="{
        text: 'Search',
        icon: 'search',
        onClick: searchData
      }" />
    </DxForm>
  </div>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid id="grid" :ref="gridRefName" :dataSource="gridData" :show-borders="true" :show-column-lines="true"
      :columnAutoWidth="true" :allowColumnResizing="true" @cell-prepared="onCellPrepared" @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)">
      <DxSearchPanel :visible="false" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="none" />
      <DxExport :enabled="true" />
      <DxColumn data-field="salesPerson" caption="SALES_PERSON" :header-filter="{ allowSearch: true }" />
      <DxColumn caption="OVER">
        <DxColumn data-field="srdOverQty" caption="QTY" dataType="number" :allowFiltering="false" format="###,###,###,###,###"/>
        <DxColumn data-field="srdOverAmount" caption="Amount(₩)" dataType="number" :allowFiltering="false" format="###,###,###,###,###"/>
      </DxColumn>
      <DxColumn caption="1W(7)">
        <DxColumn data-field="srdWw1Qty" caption="QTY" dataType='number' :allowFiltering="false" format="###,###,###,###,###"/>
        <DxColumn data-field="srdWw1Amount" caption="Amount(₩)" dataType='number' :allowFiltering="false" format="###,###,###,###,###"/>
      </DxColumn>
      <DxColumn caption="2W(14)">
        <DxColumn data-field="srdWw2Qty" caption="QTY" dataType="number"  :allowFiltering="false" format="###,###,###,###,###"/>
        <DxColumn data-field="srdWw2Amount" caption="Amount(₩)" dataType="number"  :allowFiltering="false" format="###,###,###,###,###"/>
      </DxColumn>
      <DxColumn caption="3W(21)">
        <DxColumn data-field="srdWw3Qty" caption="QTY" dataType="number"  :allowFiltering="false" format="###,###,###,###,###"/>
        <DxColumn data-field="srdWw3Amount" caption="Amount(₩)" dataType="number"  :allowFiltering="false" format="###,###,###,###,###"/>
      </DxColumn>
      <DxColumn caption="4W(28)">
        <DxColumn data-field="srdWw4Qty" caption="QTY" dataType="number"  :allowFiltering="false" format="###,###,###,###,###"/>
        <DxColumn data-field="srdWw4Amount" caption="Amount(₩)" dataType="number"  :allowFiltering="false" format="###,###,###,###,###"/>
      </DxColumn>
      <DxColumn caption="OTHERS">
        <DxColumn data-field="srdOthersQty" caption="QTY" dataType="number"  :allowFiltering="false" format="###,###,###,###,###"/>
        <DxColumn data-field="srdOthersAmount" caption="Amount(₩)" dataType="number"  :allowFiltering="false" format="###,###,###,###,###"/>
      </DxColumn>
      <DxColumn caption="TOTAL">
        <DxColumn data-field="SOrder" caption="QTY" dataType="number"  :allowFiltering="false" format="###,###,###,###,###"/>
        <DxColumn data-field="amount" caption="Amount(₩)" dataType="number"  :allowFiltering="false" format="###,###,###,###,###"/>
      </DxColumn>
      <template #sortItemTemplate>
        <DxSelectBox
          v-model:value="sortItem"
          :search-enabled="true"
          :dataSource="sortItems"
          displayExpr="Name"
          valueExpr="ID"
          placeholder="Sort Item"
          width="150"
        />
      </template>

      <template #sortTypeTemplate>
        <DxRadioGroup
          :items="['ASC', 'DESC']"
          v-model:value="sortType"
          layout="horizontal"
        />
      </template>
    </DxDataGrid>
  </div>
  <DxTagBox v-if="false" />
</template>

<script>

import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxButtonItem,
  DxLabel
} from "devextreme-vue/form";
import { DxTagBox } from 'devextreme-vue/tag-box';
import {
  DxDataGrid,
  DxScrolling,
  DxColumn,
  DxSorting,
  DxSearchPanel,
  DxHeaderFilter,
  DxExport
} from "devextreme-vue/data-grid";
import { DxSelectBox } from "devextreme-vue/select-box";
import DxRadioGroup from "devextreme-vue/radio-group";
import { location } from "../../utils/syscodedata";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import Query from 'devextreme/data/query';
// import { Workbook } from 'exceljs';
// import { saveAs } from 'file-saver-es';
// import { exportDataGrid } from 'devextreme/excel_exporter';
import { dcWW } from "../../utils/syscodedata";

export default {
  components: {
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxTagBox,
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxSorting,
    DxSearchPanel,
    DxHeaderFilter,
    DxExport,
    DxSelectBox,
    DxRadioGroup
  },
  data() {
    return {
      formData: {},
      customerInfo: [],
      itemNameList: [],
      locationList: location,
      group2NameList: [],
      salesPersonList: [],
      applicationList: [],
      productList: [],
      rawData: [],
      gridData: [],
      gridRefName: 'grid',
      visibleSearchCondition: true,
      sortItems: dcWW,
      sortItem: "srdOverAmount",
      sortType: "DESC",
    };
  },
  mounted() {
    dsu.getAxiosCustomerInfo().then(res => { this.customerInfo = res.data.data });
    dsu.getAxiosItemNameList().then(res => { this.itemNameList = res.data.data });
    dsu.getAxiosCMGroup2NameList().then(res => { this.group2NameList = res.data.data });
    dsu.getAxiosCMSalesPersonList().then(res => { this.salesPersonList = res.data.data });
    dsu.getAxiosCMApplicationList().then(res => { this.applicationList = res.data.data });
    dsu.getAxiosITMProductList().then(res => { this.productList = res.data.data });
  },
  computed: {
    grid: function () { return this.$refs[this.gridRefName].instance; },
    cSortType: function () {
      let sortType = true;
      if (this.sortType == "ASC") {
        sortType = false;
      } else if (this.sortType == "DESC") {
        sortType = true;
      }
      return sortType;
    },
  },
  methods: {
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift(
                {
          location: "after",
          locateInMenu: "auto",
          template: "sortItemTemplate",
        },
        {
          location: "after",
          locateInMenu: "auto",
          template: "sortTypeTemplate",
        },
        {
          location: "after",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            text: "Sort",
            icon: "orderedlist",
            stylingMode: "outlined",
            onClick: this.btnSort.bind(this),
          },
        },
        {
          location: 'after',
          locateInMenu: 'auto',
          widget: 'dxButton',
          options: {
            icon: 'search',
            stylingMode: "outlined",
            onClick: this.toggleSearchBox.bind(this)
          }
        }
      );
    },
        btnSort() {
      this.sort("+", true);
    },
    sort(vdStatus, showTotal) {
      const sortItem = this.sortItem;

      this.gridData = Query(this.rawData)
        .filter(["idx", "=", "1"])
        .sortBy(sortItem, this.cSortType)
        .toArray();
      this.viewDetailStatus = vdStatus;
      this.gridData.map((item) => {
        if (item.viewDetailData) {
          item.viewDetailData = this.viewDetailStatus;
        }
      });

      if (showTotal) {
        this.addTotalLine();
      }
    },
    toggleSearchBox() {
      this.visibleSearchCondition = !this.visibleSearchCondition;
    },
    searchData(e) {
      var params = {};

      params.custName = JSON.stringify(this.formData.custName);
      params.itemName = JSON.stringify(this.formData.itemName);
      params.location = JSON.stringify(this.formData.location);
      params.group2Name = JSON.stringify(this.formData.group2Name);
      params.salesPerson = JSON.stringify(this.formData.salesPerson);
      params.application = JSON.stringify(this.formData.application);
      params.product = JSON.stringify(this.formData.product);

      this.grid.beginCustomLoading('Data Loading...');
      e.component.option('disabled', true);

      dsu.getAxiosOBJPost('/api/UDLU/selectDCUser', params).then(res => {
        this.grid.clearFilter();
        let response = res.data;
        this.grid.endCustomLoading();
        e.component.option('disabled', false);
        if (response.result == 'SUCCESS') {
          this.rawData = response.data;
          this.gridData = Query(this.rawData)
            .filter(['idx', '=', '1'])
            .toArray();
          this.gridData.push(Query(this.rawData)
            .filter(['idx', '=', '3'])
            .toArray()[0]);
          this.sortItem = "srdOverAmount";
          this.sortType = "DESC";
          this.sort("+", true);
          this.toggleSearchBox();
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    getCellColor(rowType, dataField, rowData, caption) {
      const colorHeader = 'E4EEF7';
      const colorSRDOver = 'FA5940';
      const colorSRDWw1 = 'FFCF57';
      const colorSRDWw2 = 'FFFD67';
      const colorSRDWw3 = 'A6D068';
      const colorSRDWw4 = '91B3FF';
      const colorSRDOthers = 'F5EDFC';
      const colorIdx3 = 'f5dfdf';

      var color = '';

      if (rowType == 'header') {
        if (dataField == "srdOverQty" || dataField == "srdOverAmount" || caption == "OVER") {
          color = colorSRDOver;
        } else if (dataField == "srdWw1Qty" || dataField == "srdWw1Amount" || caption == "1W(7)") {
          color = colorSRDWw1;
        } else if (dataField == "srdWw2Qty" || dataField == "srdWw2Amount" || caption == "2W(14)") {
          color = colorSRDWw2;
        } else if (dataField == "srdWw3Qty" || dataField == "srdWw3Amount" || caption == "3W(21)") {
          color = colorSRDWw3;
        } else if (dataField == "srdWw4Qty" || dataField == "srdWw4Amount" || caption == "4W(28)") {
          color = colorSRDWw4;
        } else if (dataField == "srdOthersQty" || dataField == "srdOthersAmount" || caption == "OTHERS") {
          color = colorSRDOthers;
        } else {
          color = colorHeader;
        }
      } else if (rowType == 'data') {
        if (rowData.idx == '3') {
          color = colorIdx3;
        }
      }
      return color;
    },
    getFontWeight(rowType, rowData) {
      var fontWeight = false;
      if (rowType == 'data') {
        if (rowData.idx == '1' || rowData.idx == '3') {
          fontWeight = true;
        }
      }

      return fontWeight;
    },
    onCellPrepared(e) {
      var bgColor = this.getCellColor(e.rowType, e.column.dataField, e.data, e.column.caption);
      if (bgColor) {
        e.cellElement.bgColor = '#' + bgColor;
      }
      var fontBold = this.getFontWeight(e.rowType, e.data);
      if (fontBold) {
        e.cellElement.style.fontWeight = 'bold';
      }
    },
    onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
    // onExporting(e) {
    //   const workbook = new Workbook();
    //   const worksheet = workbook.addWorksheet('Sales Person');

    //   exportDataGrid({
    //     component: e.component,
    //     worksheet,
    //     keepColumnWidths: false,
    //     customizeCell: ({ gridCell, excelCell }) => {

    //       const rowType = gridCell.rowType;
    //       const dataField = gridCell.column.dataField;
    //       const dataType = gridCell.column.dataType;
    //       const rowData = gridCell.data;

    //       if(rowType =='data'&& dataType == 'number'){
    //         excelCell.numFmt = "###,###,###,###,###";
    //       }

    //       var bgColor = this.getCellColor(rowType, dataField, rowData);
    //       if (bgColor) {
    //         excelCell.fill = {
    //           type: 'pattern',
    //           pattern: 'darkVertical',
    //           fgColor: { argb: 'FF' + bgColor },
    //           bgColor: { argb: 'FF' + bgColor }
    //         };
    //       }

    //       var fontStyle = {};
    //       fontStyle.bold = this.getFontWeight(rowType, dataField);
    //       if (fontStyle) {
    //         excelCell.font = fontStyle;
    //       }
    //     }

    //   }).then(() => {
    //     workbook.xlsx.writeBuffer().then((buffer) => {
    //       saveAs(new Blob([buffer], { type: 'application/octet-stream' }), this.$route.meta.transName + '.xlsx');
    //     });
    //   });
    //   e.cancel = true;
    // },
  }
};
</script>

<style lang="scss">
#grid {
  max-height: calc(100vh - 140px);
}
</style>
