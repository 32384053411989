export const userAuth = [
  { ID: 'USER', Name: 'The Person in Charge' },
  { ID: 'MANAGER', Name: 'Manager' },
  { ID: 'ADMIN', Name: 'System Administrator' }
];

export const location = [
  { ID: 'KOR', Name: 'KOR' },
  { ID: 'HK', Name: 'HK' },
  { ID: 'EU', Name: 'EU' },
  { ID: 'CHEM', Name: 'CHEM' },
  { ID: 'POL', Name: 'POL' },
  { ID: 'WD', Name: 'WD' },
  { ID: 'ZQ', Name: 'ZQ' },
  { ID: 'TH', Name: 'TH' },
];

export const locationFundFlow = [
  { ID: 'KOR', Name: 'KOR' },
  { ID: 'HK', Name: 'HK' },
  { ID: 'EU', Name: 'EU' },
  { ID: 'USA', Name: 'USA' },
  { ID: 'WD', Name: 'WD' },
  { ID: 'ZQ', Name: 'ZQ' },
  { ID: 'TH', Name: 'TH' },
];

export const dcWW = [
  { ID: 'srdOverAmount', Name: 'OVER' },
  { ID: 'srdWw1Amount', Name: '1W(7)' },
  { ID: 'srdWw2Amount', Name: '2W(14)' },
  { ID: 'srdWw3Amount', Name: '3W(21)' },
  { ID: 'srdWw4Amount', Name: '4W(28)' },
  { ID: 'srdOthersAmount', Name: 'OTHERS' },
  { ID: 'amount', Name: 'TOTAL' }
];

export const dateType = [
  { ID: 'SRD_DATE', Name: 'SRD_DATE' },
  { ID: 'DELI_DATE', Name: 'DELI_DATE' }
];

export const locType = [
  { ID: 'ALL', Name: 'ALL' },
  { ID: 'SALES', Name: 'SALES' },
  { ID: 'FACTORY', Name: 'FACTORY' }
];


export const Months = [
  { "ID": 1, "Name": "Jan" },
  { "ID": 2, "Name": "Feb" },
  { "ID": 3, "Name": "Mar" },
  { "ID": 4, "Name": "Apr" },
  { "ID": 5, "Name": "May" },
  { "ID": 6, "Name": "Jun" },
  { "ID": 7, "Name": "Jul" },
  { "ID": 8, "Name": "Aug" },
  { "ID": 9, "Name": "Sep" },
  { "ID": 10, "Name": "Oct" },
  { "ID": 11, "Name": "Nov" },
  { "ID": 12, "Name": "Dec" }
];

