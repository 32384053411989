<template>
  <div class="content-block dx-card responsive-paddings">
    <div class="pTitle">
        ▶ 자금 - 현금
    </div>
    <DxDataGrid id="grid" :ref="gridFund1RefName" :dataSource="dataSourceFund1" :show-borders="true" :show-column-lines="true" :columnAutoWidth="true" :allowColumnResizing="true" @cell-prepared="onCellPrepared" @exporting="onExporting($event, '자금 - 현금')">
      <DxScrolling mode="virtual" />
      <DxExport :enabled="true" />
      <DxColumn data-field="currency" caption="통화"/>
      <DxColumn data-field="bankAccount" caption="통장"/>
      <DxColumn data-field="amountYesterday" caption="전일 잔액" format="###,###,###,###,###"/>
      <DxColumn data-field="amountYesterdayKrw" caption="전일 잔액(KRW)" format="###,###,###,###,###"/>
      <DxColumn data-field="deposit" caption="입금" format="###,###,###,###,###"/>
      <DxColumn data-field="depositKrw" caption="입금(KRW)" format="###,###,###,###,###"/>
      <DxColumn data-field="withdraw" caption="출금" format="###,###,###,###,###"/>
      <DxColumn data-field="withdrawKrw" caption="출금(KRW)" format="###,###,###,###,###"/>
      <DxColumn data-field="amount" caption="금일 잔액" format="###,###,###,###,###"/>
      <DxColumn data-field="amountKrw" caption="금일 잔액(KRW)" format="###,###,###,###,###"/>  
    </DxDataGrid>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <div class="pTitle">
        ▶ 자금 - 예금
    </div>
    <DxDataGrid id="grid" :ref="gridFund2RefName" :dataSource="dataSourceFund2" :show-borders="true" :show-column-lines="true" :columnAutoWidth="true" :allowColumnResizing="true" @cell-prepared="onCellPrepared" @exporting="onExporting($event, '자금 - 예금')">
      <DxScrolling mode="virtual" />
      <DxExport :enabled="true" />
      <DxColumn data-field="currency" caption="통화"/>
      <DxColumn data-field="bankAccount" caption="통장"/>
      <DxColumn data-field="amountYesterday" caption="전일 잔액" format="###,###,###,###,###"/>
      <DxColumn data-field="amountYesterdayKrw" caption="전일 잔액(KRW)" format="###,###,###,###,###"/>
      <DxColumn data-field="deposit" caption="입금" format="###,###,###,###,###"/>
      <DxColumn data-field="depositKrw" caption="입금(KRW)" format="###,###,###,###,###"/>
      <DxColumn data-field="withdraw" caption="출금" format="###,###,###,###,###"/>
      <DxColumn data-field="withdrawKrw" caption="출금(KRW)" format="###,###,###,###,###"/>
      <DxColumn data-field="amount" caption="금일 잔액" format="###,###,###,###,###"/>
      <DxColumn data-field="amountKrw" caption="금일 잔액(KRW)" format="###,###,###,###,###"/>  
    </DxDataGrid>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <div class="pTitle">
        ▶ 자금 - 예적금
    </div>
    <DxDataGrid id="grid" :ref="gridFund2RefName" :dataSource="dataSourceFund3" :show-borders="true" :show-column-lines="true" :columnAutoWidth="true" :allowColumnResizing="true" @cell-prepared="onCellPrepared" @exporting="onExporting($event, '자금 - 예적금')">
      <DxScrolling mode="virtual" />
      <DxExport :enabled="true" />
      <DxColumn data-field="currency" caption="통화"/>
      <DxColumn data-field="bankAccount" caption="통장"/>
      <DxColumn data-field="amountYesterday" caption="전일 잔액" format="###,###,###,###,###"/>
      <DxColumn data-field="amountYesterdayKrw" caption="전일 잔액(KRW)" format="###,###,###,###,###"/>
      <DxColumn data-field="deposit" caption="입금" format="###,###,###,###,###"/>
      <DxColumn data-field="depositKrw" caption="입금(KRW)" format="###,###,###,###,###"/>
      <DxColumn data-field="withdraw" caption="출금" format="###,###,###,###,###"/>
      <DxColumn data-field="withdrawKrw" caption="출금(KRW)" format="###,###,###,###,###"/>
      <DxColumn data-field="amount" caption="금일 잔액" format="###,###,###,###,###"/>
      <DxColumn data-field="amountKrw" caption="금일 잔액(KRW)" format="###,###,###,###,###"/>
    </DxDataGrid>
  </div>

   <div class="content-block dx-card responsive-paddings">
    <div class="pTitle">
        ▶ 채권 - 거래처
    </div>
    <DxDataGrid id="grid" :ref="gridBondRefName" :dataSource="dataSourceBond" :show-borders="true" :show-column-lines="true" :columnAutoWidth="true" :allowColumnResizing="true" @cell-prepared="onCellPrepared" @exporting="onExporting($event, '채권 - 거래처')">
      <DxScrolling mode="virtual" />
      <DxExport :enabled="true" />
      <DxColumn data-field="businessPartner" caption="거래처"/>
      <DxColumn data-field="currency" caption="통화"/>
      <DxColumn data-field="amount" caption="금일 잔액" format="###,###,###,###,###"/>
      <DxColumn data-field="amountKrw" caption="금일 잔액(KRW)" format="###,###,###,###,###"/>
    </DxDataGrid>
  </div> 

    <div class="content-block dx-card responsive-paddings">
    <div class="pTitle">
        ▶ 채무 - 거래처
    </div>
    <DxDataGrid id="grid" :ref="gridDebtRefName" :dataSource="dataSourceDebt" :show-borders="true" :show-column-lines="true" :columnAutoWidth="true" :allowColumnResizing="true" @cell-prepared="onCellPrepared" @exporting="onExporting($event, '채무 - 거래처')"> 
      <DxScrolling mode="virtual" />
      <DxExport :enabled="true" />
      <DxColumn data-field="businessPartner" caption="거래처"/>
      <DxColumn data-field="currency" caption="통화"/>
      <DxColumn data-field="amount" caption="금일 잔액" format="###,###,###,###,###"/>
      <DxColumn data-field="amountKrw" caption="금일 잔액(KRW)" format="###,###,###,###,###"/>
    </DxDataGrid>
  </div> 
</template>

<script>
  import {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxExport
  } from "devextreme-vue/data-grid";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  import Query from "devextreme/data/query";
  // import { Workbook } from "exceljs";
  // import { saveAs } from "file-saver-es";
  // import { exportDataGrid } from "devextreme/excel_exporter";

  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxExport
    },
    props: {
      location: String,
      dt: String
    },
    data() {
      return {
        grid1RefName: 'gr',
        dataSourceFund1: [],
        dataSourceFund2: [],
        dataSourceFund3: [],
        dataSourceBond: [],
        dataSourceDebt: [],
      };
    },

  methods: {
    getCellColor(rowType) {
      const colorHeader = "E4EEF7";
      var color = "";

      if (rowType == "header") {
          color = colorHeader;
        }
    // } 
    //   else if(rowType == "data") {
    //     // if (rowData.idx == "1") {
    //     //   color = colorIdx1;
    //     // }
    //     // if (rowData.idx == "3") {
    //     //   color = colorIdx3;
    //     // }
    //   }
      return color;
    },
    onCellPrepared(e) {
      var bgColor = this.getCellColor(
        e.rowType,
        e.column.dataField,
        e.data,
        e.column.caption
      );
      if (bgColor) {
        e.cellElement.bgColor = "#" + bgColor;
      }
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    // onExporting(e, fileName) {
    //   const workbook = new Workbook();
    //   const worksheet = workbook.addWorksheet("data");

    //   exportDataGrid({
    //     component: e.component,
    //     worksheet,
    //     keepColumnWidths: false,
    //     customizeCell: ({ gridCell, excelCell }) => {
    //       const rowType = gridCell.rowType;
    //       const dataField = gridCell.column.dataField;
    //       const dataType = gridCell.column.dataType;
    //       const rowData = gridCell.data;

    //       if (rowType == "data" && dataType == "number") {
    //         excelCell.numFmt = "###,###,###,###,###";
    //       }

    //       var bgColor = this.getCellColor(rowType, dataField, rowData);
    //       if (bgColor) {
    //         excelCell.fill = {
    //           type: "pattern",
    //           pattern: "darkVertical",
    //           fgColor: { argb: "FF" + bgColor },
    //           bgColor: { argb: "FF" + bgColor },
    //         };
    //       }
    //     },
    //   }).then(() => {
    //     workbook.xlsx.writeBuffer().then((buffer) => {
    //       saveAs(
    //         new Blob([buffer], { type: "application/octet-stream" }),
    //         this.location + '_' + dsu.getFormatDate(this.dt) + '_' + fileName + ".xlsx"
    //       );
    //     });
    //   });
      
    //   e.cancel = true;
    // },
    refreshData() {
      var params = {};
      params.location = this.location;
      params.dt = dsu.getFormatDate(this.dt);

      dsu.getAxiosOBJGet("/api/UFSL/selectFundFlowSummaryFund", params)
      .then((res) => {
        let response = res.data;
        if (response.result == "SUCCESS") {
          if (response.data.length > 0) {
            this.dataSourceFund1 = Query(response.data).filter(["category", "=", "현금"]).toArray();
            this.dataSourceFund2 = Query(response.data).filter(["category", "=", "예금"]).toArray();
            this.dataSourceFund3 = Query(response.data).filter(["category", "=", "예적금"]).toArray();
          }
        } else {
          fn.notifyError(response.msg);
        }
      });

      dsu.getAxiosOBJGet("/api/UFSL/selectFundFlowSummaryBondDebt", params)
      .then((res) => {
        let response = res.data;
        if (response.result == "SUCCESS") {
          if (response.data.length > 0) {
            this.dataSourceBond = Query(response.data).filter(["category", "=", "채권"]).toArray();
            this.dataSourceDebt = Query(response.data).filter(["category", "=", "채무"]).toArray();
          }
        } else {
          fn.notifyError(response.msg);
        }
      });
    }
    }
  };
</script>

<style lang="scss">
  .pTitle {
    font-size: 18px;
    font-weight: bold;
    margin-top: 10px;
    margin-bottom: 20px;
  }
</style>