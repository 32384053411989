<template>
  <div class="content-block dx-card responsive-paddings">
      <DxDataGrid
        id="checklist"
        :ref="gridRefName"
        :data-source="dataSource"
        :show-borders="true"
        :show-column-lines="true"
        :columnAutoWidth="true"
        :focused-row-enabled="true"
        key-expr="id"
        @exporting="onExporting"
        @focused-row-changed="selectedRowChanged($event)"
        @toolbar-preparing="onToolbarPreparing($event)"
        @editorPreparing="onEditorPreparing($event)"
        @row-inserting="onRowInserting">
        
        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        <DxEditing :allow-updating="true"
                :allow-adding="true"
                :allow-deleting="true"
                mode="popup">

                <DxPopup
                  :show-title="true"
                  height="auto"
                  title="Modify"
                />
        </DxEditing>
        <DxExport :enabled="true"/>

        <DxColumn type="buttons">
          <DxButton name="edit"/>
        </DxColumn>

        <DxColumn
          data-field="factoryCode"
          caption="Factory">
            <DxLookup :data-source="factoryList"
                      value-expr="factoryCode"
                      display-expr="factoryName"/>
          <DxRequiredRule/>
        </DxColumn>

        <DxColumn
          data-field="id"
          caption="ID">
          <DxRequiredRule/>
          <DxPatternRule
            pattern="^[A-Za-z0-9-_]{1,10}$"
            message="영문 숫자 '-(대쉬)' '_(언더 바)' 로만 구성, 10자 까지 등록 가능"/>
        </DxColumn>

        <DxColumn
          data-field="name"
          caption="Name">
          <DxRequiredRule/>
          <DxPatternRule
            pattern="^.{1,30}$"
            message="30자 까지 등록 가능"/>
        </DxColumn>

        <DxColumn
          data-field="cat1Name"
          caption="Category 1 Name">
          <DxRequiredRule/>
          <DxPatternRule
            pattern="^.{1,30}$"
            message="30자 까지 등록 가능"/>
        </DxColumn>       

        <DxColumn
          data-field="cat2Name"
          caption="Category 2 Name">
          <DxRequiredRule/>
          <DxPatternRule
            pattern="^.{1,30}$"
            message="30자 까지 등록 가능"/>
        </DxColumn>    

        <DxColumn
          data-field="cat3Name"
          caption="Category 3 Name">
          <DxRequiredRule/>
          <DxPatternRule
            pattern="^.{1,30}$"
            message="30자 까지 등록 가능"/>
        </DxColumn>    
        
        <DxColumn
          data-field="scorePass"
          caption="Pass Criterion"
          data-type="number"
          :editor-options="{min: 1, max: 100}">
          <DxRequiredRule/>
        </DxColumn>    

        <DxColumn
          data-field="scoreReview"
          caption="Review Criterion"
          data-type="number"
          :editor-options="{min: 1, max: 100}">
          <DxRequiredRule/>
        </DxColumn>    

        <DxMasterDetail
          :enabled="true"
          template="master-detail"/>
          
          <template #master-detail="{ data }">
            <History :master-detail-data="data"
                    :url= "`/api/ACLI/selectHistory`"
                    :keyName="id"
                    :columns="historyColumns"/>
          </template>    

          <template #gridSubjectTemplate>
            <div class="gridSubject">Check List</div>
          </template>
      </DxDataGrid>
  </div>
  
  <CategoryList :checkListId="selCheckListId"
                :cat1Name="selCat1Name"
                :cat2Name="selCat2Name"/>
  
</template>

<script>
  import {DxDataGrid,
          DxScrolling,
          DxColumn,
          DxSorting,
          DxSearchPanel,
          DxHeaderFilter,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail,
          DxEditing,
          DxExport,
          DxPopup,
          DxLookup} from "devextreme-vue/data-grid";
  import History from "../_common/SIMPLE_HISTORY";
  import CategoryList from "../_common/CATEGORY_LIST";
  import dsu from "../../utils/dataSourceUtils";
  import {userAuth} from "../../utils/syscodedata";

  export default {
    components: {
      DxDataGrid,
      DxScrolling,
      DxColumn,
      DxSorting,
      DxSearchPanel,
      DxHeaderFilter,
      DxMasterDetail,
      DxRequiredRule,
      DxPatternRule,
      DxEditing,
      History,
      DxExport,
      CategoryList,
      DxPopup,
      DxLookup
    },
    data() {
      return {
        formData: {},
        factoryList: [],
        gridRefName: 'dataGrid',
        userAuth: userAuth,
        dataSource: dsu.getComDSGrid('/api/ACLI', 'id', '', 'Check List ID'),
        historyColumns: [{dataField: "transTime", caption : "Update Time"},
                        {dataField: "factoryCode", caption : "Factory"},
                        {dataField: "name", caption: "Name"},
                        {dataField: "cat1Name", caption: "Category 1 Name"},
                        {dataField: "cat2Name", caption: "Category 2 Name"},
                        {dataField: "cat3Name", caption: "Category 3 Name"},
                        {dataField: "scorePass", caption: "Pass Score"},
                        {dataField: "scoreReview", caption: "Review Score"},
                        {dataField: "dmlUserId", caption: "User"},
                        {dataField: "dml", caption: "Action"}],
        selCheckListId: '',
        selCat1Name: 'Category 1',
        selCat2Name: 'Category 2',
        onToolbarPreparing(e) {
          e.toolbarOptions.items.unshift(
            {location: 'before',
             locateInMenu: 'auto',
             template: 'gridSubjectTemplate'}
          );
        }
      };
    },
    mounted(){
      dsu.getAxiosFactoryInfo().then(res => {this.factoryList = res.data.data});
    },
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
      selectedRowChanged(e){
        var data = e.row.data;

        this.selCheckListId = data.id;
        this.selCat1Name = data.cat1Name;
        this.selCat2Name = data.cat2Name;
      },      
      onEditorPreparing(e) { 
        if (e.dataField == 'id'){
          e.editorOptions.inputAttr = {'style':  "text-transform: uppercase"} ;
        }
      },
      onRowInserting(e){
        e.data.id = e.data.id.toUpperCase();
      }      
    }
  };
</script>

<style scoped>
  #checklist{
    max-height: 350px !important;
  }
</style>