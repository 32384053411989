<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm :ref="formTLLRefName" label-location="left" :formData="formData">
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />
      <DxSimpleItem data-field="authGroup" :colspan="2">
        <template #default>
          <DxLabel>Auth Group</DxLabel>
          <DxSelectBox
            :ref="selectBoxRefName"
            :items="authGroupList"
            :searchEnabled="true"
            valueExpr="groupId"
            displayExpr="groupName"
            @valueChanged="onValueChanged"
          />
        </template>
      </DxSimpleItem>

      <DxSimpleItem
        data-field="authGroupName"
        editor-type="dxTextBox"
        :editor-options="{ maxLength: 30 }"
      >
        <DxLabel text="Auth Group" />
      </DxSimpleItem>

      <DxButtonItem
        horizontal-alignment="left"
        vertical-alignment="center"
        :buttonOptions="{
          text: 'New Reg',
          icon: 'add',
          onClick: newRegistration,
        }"
      />
    </DxForm>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="gridContainer"
      :ref="gridRefName"
      :data-source="transactionList"
      :show-borders="true"
      :show-column-lines="true"
      @exporting="onExporting"
      keyExpr="transaction"
      width="100%"
      @cell-click="onCellCkick"
    >
      <DxToolbar>
        <DxItem
          location="before"
          locateInMenu="auto"
          template="groupNameTemplate"
        />
        <DxItem
          location="after"
          locateInMenu="auto"
          template="saveButtonTemplate"
        />
        <DxItem
          location="after"
          locateInMenu="auto"
          template="removeButtonTemplate"
        />
        <DxItem name="columnChooserButton" />
      </DxToolbar>
      <DxScrolling mode="virtual" />
      <DxGrouping :auto-expand-all="true" />
      <DxSorting mode="none" />
      <DxColumn :group-index="0" data-field="groupName" caption="메뉴 그룹" />
      <DxColumn data-field="menuName" caption="Menu" :width="300" />
      <DxColumn
        data-field="menuAuth"
        caption="Menu Auth"
        :width="120"
        dataType="boolean"
      />
      <DxColumn
        data-field="f1Auth"
        caption=""
        cell-template="function1Template"
        alignment="left"
      />

      <template #groupNameTemplate>
        <div className="dx-field">
          <div className="dx-field-label">Auth Group Name</div>
          <div className="dx-field-value">
            <DxTextBox
              v-model:value="selectedGroupName"
              :width="250"
              :maxLength="30"
            />
          </div>
        </div>
      </template>
      <template #saveButtonTemplate>
        <DxButton text="Save" icon="save" @click="save" />
      </template>
      <template #removeButtonTemplate>
        <DxButton text="Delete" icon="remove" @click="remove" />
      </template>
      <template #function1Template="{ data }">
        <DxCheckBox
          v-if="data.data.f1Name"
          :text="data.data.f1Name"
          :value="data.data.f1Auth"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxToolbar,
  DxItem,
  DxGrouping,
  DxSorting,
} from "devextreme-vue/data-grid";
import DxForm, {
  DxColCountByScreen,
  DxLabel,
  DxSimpleItem,
  DxButtonItem,
} from "devextreme-vue/form";
import { DxButton } from "devextreme-vue/button";
import { DxTextBox } from "devextreme-vue/text-box";
import DxSelectBox from "devextreme-vue/select-box";
import { DxCheckBox } from "devextreme-vue/check-box";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxForm,
    DxColCountByScreen,
    DxLabel,
    DxSimpleItem,
    DxButtonItem,
    DxToolbar,
    DxItem,
    DxButton,
    DxTextBox,
    DxSelectBox,
    DxGrouping,
    DxSorting,
    DxCheckBox,
  },
  data() {
    return {
      authGroupList: [],
      selectedGroupId: "",
      selectedGroupName: "",
      formData: {},
      gridRefName: "grid",
      selectBoxRefName: "selectBox",
      transactionList: [],
    };
  },
  mounted() {
    this.refreshAuthGrooupInfo();
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
    selectBox: function () {
      return this.$refs[this.selectBoxRefName].instance;
    },
  },
  methods: {
    refreshAuthGrooupInfo(value) {
      dsu.getAxiosOBJGet("/api/AMGR/selectAuthGroupInfo").then((res) => {
        let rtnMsg = res.data.msg;
        if (res.data.result == "SUCCESS") {
          this.authGroupList = res.data.data;
          if (value) {
            setTimeout(() => {
              this.selectBox.option("value", value);
            }, 1000);
          }
        } else {
          fn.notifyError(rtnMsg);
        }
      });
    },
    newRegistration(e) {
      var params = { groupName: this.formData.authGroupName };
      if (!params.groupName) {
        fn.notifyError("등록할 Auth Group 입력 필요");
        return;
      }

      e.component.option("disabled", true);
      dsu
        .getAxiosOBJPost("/api/AMGR/insertAuthGroupInfo", params)
        .then((res) => {
          let rtnMsg = res.data.msg;
          if (res.data.result == "SUCCESS") {
            fn.notifySuccess(rtnMsg);
            this.refreshAuthGrooupInfo(res.data.data);
            this.formData.authGroupName = "";
          } else {
            fn.notifyError(rtnMsg);
          }
          e.component.option("disabled", false);
        });
    },
    onValueChanged(e) {
      var params = {};
      this.selectedGroupId = e.value;
      this.selectedGroupName = e.component.option("displayValue");
      params.groupId = e.value;

      dsu.getAxiosOBJGet("/api/AMGR/selectAuthGroup", params).then((res) => {
        let response = res.data;
        if (res.data.result == "SUCCESS") {
          var finalData = response.data.map((item) =>
            item.menuAuth === "true"
              ? { ...item, menuAuth: true }
              : { ...item, menuAuth: false }
          );
          this.transactionList = finalData.map((item) =>
            item.f1Auth === "true"
              ? { ...item, f1Auth: true }
              : { ...item, f1Auth: false }
          );
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    save(e) {
      var params = {};
      if (!this.selectedGroupId) {
        fn.notifyError("Auth Group 선택 후 진행 필요");
        return;
      }
      params.groupId = this.selectedGroupId;
      params.groupName = this.selectedGroupName;
      params.transactionList = this.transactionList.filter(
        (item) => item.menuAuth
      );
      if (params.transactionList.length < 1) {
        fn.notifyError("하나 이상의 권한 설정 후 진행 필요");
        return;
      }
      params.transactionList = JSON.stringify(params.transactionList);

      e.component.option("disabled", true);
      dsu.getAxiosOBJPost("/api/AMGR/insertAuthGroup", params).then((res) => {
        let rtnMsg = res.data.msg;
        if (res.data.result == "SUCCESS") {
          fn.notifySuccess(rtnMsg);
          this.refreshAuthGrooupInfo(this.selectedGroupId);
        } else {
          fn.notifyError(rtnMsg);
        }
        e.component.option("disabled", false);
      });
    },
    async remove(e) {
      var params = {};
      if ((await fn.confirm("정말 삭제 하시겠습니까?")) == true) {
        if (!this.selectedGroupId) {
          fn.notifyError("Auth Group 선택 후 진행 필요");
          return;
        }
        params.groupId = this.selectedGroupId;
        e.component.option("disabled", true);
        dsu
          .getAxiosOBJPost("/api/AMGR/deleteAuthGroupAll", params)
          .then((res) => {
            let rtnMsg = res.data.msg;
            if (res.data.result == "SUCCESS") {
              fn.notifySuccess(rtnMsg);
              this.refreshAuthGrooupInfo();
              this.clearSelectedAuthGroupInfo();
            } else {
              fn.notifyError(rtnMsg);
            }
            e.component.option("disabled", false);
          });
      }
    },
    clearSelectedAuthGroupInfo() {
      this.selectedGroupId = "";
      this.selectedGroupName = "";
      this.transactionList = [];
    },
    onCellCkick(e) {
      let dataField = "";
      let rowType = e.rowType;

      if (rowType == "header") {
        dataField = e.column.dataField;
        if (dataField == "menuAuth") {
          const result = this.transactionList.filter(
            (item) => item[dataField] == false
          );
          let convertValue = false;
          if (result.length > 0) {
            convertValue = true;
          } else {
            convertValue = false;
          }
          this.transactionList = this.transactionList.map((item) => {
            return { ...item, menuAuth: convertValue };
          });
        }
      }
      if (rowType == "group") {
        const result = this.transactionList.filter(
          (item) => item.groupName == e.value && item.menuAuth == false
        );
        let convertValue = false;
        if (result.length > 0) {
          convertValue = true;
        } else {
          convertValue = false;
        }

        this.transactionList = this.transactionList.map((item) =>
          item.groupName === e.value
            ? { ...item, menuAuth: convertValue }
            : item
        );
      } else if (rowType == "data") {
        dataField = e.column.dataField;
        if (dataField == "menuAuth") {
          e.data[dataField] = !e.data[dataField];
        } else if (dataField == "f1Auth") {
          if (e.data.f1Name) {
            e.data[dataField] = !e.data[dataField];
          }
        }
      }
    },
  },
};
</script>

<style scoped>
#gridContainer {
  height: calc(100vh - 330px);
}
</style>
