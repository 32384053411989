import auth from "./auth";
import { createRouter, createWebHistory } from "vue-router";

import Home from "./views/home";
import defaultLayout from "./layouts/side-nav-inner-toolbar";
import simpleLayout from "./layouts/single-card";
import ChangePassword from "./views/change-password"
import fn from "./utils/function";

import AUSR from "./views/_transaction/AUSR";
import AVUL from "./views/_transaction/AVUL";
import ACLI from "./views/_transaction/ACLI";
import ACII from "./views/_transaction/ACII";
import UCLR from "./views/_transaction/UCLR";
import UCLS from "./views/_transaction/UCLS";
import USDC from "./views/_transaction/USDC";
import AFTR from "./views/_transaction/AFTR";
import AMGR from "./views/_transaction/AMGR";
import UDLM from "./views/_transaction/UDLM";
import UDLC from "./views/_transaction/UDLC";
import UDLI from "./views/_transaction/UDLI";
import UDLU from "./views/_transaction/UDLU";
import USDS from "./views/_transaction/USDS";
import USDA from "./views/_transaction/USDA";
import USDB from "./views/_transaction/USDB";
import URFL from "./views/_transaction/URFL";
import URUA from "./views/_transaction/URUA";
import URAT from "./views/_transaction/URAT";
import UEXR from "./views/_transaction/UEXR";
import ULBA from "./views/_transaction/ULBA";
import ULMC from "./views/_transaction/ULMC";
import UFSD from "./views/_transaction/UFSD";
import UFSL from "./views/_transaction/UFSL";
import UFSE from "./views/_transaction/UFSE";
import USRD from "./views/_transaction/USRD";
import UBBS from "./views/_transaction/UBBS";
import UBBL from "./views/_transaction/UBBL";
import USSD from "./views/_transaction/USSD";
import USSL from "./views/_transaction/USSL";
import UIDB from "./views/_transaction/UIDB";
import UIDM from "./views/_transaction/UIDM";
import MFSP from "./views/_transaction/MFSP";
import MASP from "./views/_transaction/MASP";

//앱 뷰 전용 
import VATB from "./views/_app_if/VATB";

function loadView(view) {
  return () => import(/* webpackChunkName: "login" */ `./views/${view}.vue`)
}

const router = new createRouter({
  history: createWebHistory(),
  routes: [
    {
      path: "/home",
      name: "home",
      meta: {
        transName: "Home",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: Home
    },
    {
      path: "/login-form",
      name: "login-form",
      meta: {
        requiresAuth: false,
        layout: simpleLayout,
        title: "Sign In"
      },
      component: loadView("login-form")
    },
    {
      path: "/change-password",
      name: "change-password",
      meta: {
        transName: "Change Password",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ChangePassword
    },
    {
      path: "/",
      redirect: "/home"
    },
    {
      path: "/:pathMatch(.*)*",
      redirect: "/home"
    },
    {
      path: "/ausr",
      name: "ausr",
      meta: {
        transName: "User Info",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AUSR
    },
    {
      path: "/avul",
      name: "avul",
      meta: {
        transName: "Login History",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AVUL
    },
    {
      path: "/acli",
      name: "acli",
      meta: {
        transName: "Check List Info",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ACLI
    },
    {
      path: "/acii",
      name: "acii",
      meta: {
        transName: "Check Item Info",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ACII
    },
    {
      path: "/uclr",
      name: "uclr",
      meta: {
        transName: "Check List Record",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UCLR
    },
    {
      path: "/ucls",
      name: "ucls",
      meta: {
        transName: "Check List Search",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UCLS
    },
    {
      path: "/aftr",
      name: "aftr",
      meta: {
        transName: "Factory Info",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AFTR
    },
    {
      path: "/usdc",
      name: "usdc",
      meta: {
        transName: "Delivery Data",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: USDC
    },
    {
      path: "/amgr",
      name: "amgr",
      meta: {
        transName: "Auth Group Info",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: AMGR
    },
    {
      path: "/udlm",
      name: "udlm",
      meta: {
        transName: "Customer(Main) - Delivery Date Cordination",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UDLM
    },
    {
      path: "/udlc",
      name: "udlc",
      meta: {
        transName: "Customer(Detail) - Delivery Date Cordination",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UDLC
    },
    {
      path: "/udli",
      name: "udli",
      meta: {
        transName: "Item(Detail) - Delivery Date Cordination",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UDLI
    },
    {
      path: "/udlu",
      name: "udlu",
      meta: {
        transName: "Sales Person - Delivery Date Cordination",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UDLU
    },
    {
      path: "/usds",
      name: "usds",
      meta: {
        transName: "Delivery Status",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: USDS
    },
    {
      path: "/usda",
      name: "usda",
      meta: {
        transName: "Abnormal(SIM) - Delivery Date Cordination",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: USDA
    },
    {
      path: "/usdb",
      name: "usdb",
      meta: {
        transName: "Abnormal(Over) - Delivery Date Cordination",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: USDB
    },
    {
      path: "/urfl",
      name: "urfl",
      meta: {
        transName: "Fund Data",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: URFL
    },
    {
      path: "/urua",
      name: "urua",
      meta: {
        transName: "Inventory Amount",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: URUA
    },
    {
      path: "/urat",
      name: "urat",
      meta: {
        transName: "Account",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: URAT
    },
    {
      path: "/uexr",
      name: "uexr",
      meta: {
        transName: "Exchange Rate",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UEXR
    },
    {
      path: "/ulba",
      name: "ulba",
      meta: {
        transName: "Budget by Account",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ULBA
    },
    {
      path: "/ulmc",
      name: "ulmc",
      meta: {
        transName: "Daily Closing",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: ULMC
    },
    {
      path: "/ufsd",
      name: "ufsd",
      meta: {
        transName: "Status(All)",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UFSD
    },
    {
      path: "/ufsl",
      name: "ufsl",
      meta: {
        transName: "Fund Status(Daily)",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UFSL
    },
    {
      path: "/ufse",
      name: "ufse",
      meta: {
        transName: "SG&A Expenses",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UFSE
    },
    {
      path: "/usrd",
      name: "usrd",
      meta: {
        transName: "Sales Data",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: USRD
    },
    {
      path: "/ubbs",
      name: "ubbs",
      meta: {
        transName: "Forcast - Sales",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UBBS
    },
    {
      path: "/ubbl",
      name: "ubbl",
      meta: {
        transName: "Forcast - Sales(Location)",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UBBL
    },
    {
      path: "/ussd",
      name: "ussd",
      meta: {
        transName: "Sales Status",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: USSD
    },
    {
      path: "/ussl",
      name: "ussl",
      meta: {
        transName: "Sales Status(Location)",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: USSL
    },
    {
      path: "/mfsp",
      name: "mfsp",
      meta: {
        transName: "Factory Sales Price",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MFSP
    },
    {
      path: "/masp",
      name: "masp",
      meta: {
        transName: "Sales Cost, Price, Profit",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: MASP
    },
    {
      path: "/uidb",
      name: "uidb",
      meta: {
        transName: "Integrated Business",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UIDB
    },
    {
      path: "/uidm",
      name: "uidm",
      meta: {
        transName: "Integrated Business(Mobile)",
        requiresAuth: true,
        layout: defaultLayout
      },
      component: UIDM
    },
    {
      path: "/vatb",
      name: "vatb",
      meta: {
        requiresAuth: false,
        layout: VATB
      }
    },
  ]
});

router.beforeEach((to, from, next) => {
  if (to.name == "logout") {
    next();
  }

  auth.loggedIn(to.name, 'Y').then(loggedIn => {
    if (to.name === "login-form" && loggedIn == 'SUCCESS') {
      next({ name: "home" });
    }

    if (to.matched.some(record => record.meta.requiresAuth)) {
      if (loggedIn == 'NEED_CHANGE_PASSWORD') {
        fn.alert("초기 Change Password 후 사용 필요", "정보");
        if (from.name != 'change-password') {
          next({ name: "change-password" });
        }
      } else if (loggedIn != 'SUCCESS') {
        next({ name: "login-form" });
      } else {
        next();
      }
    } else {
      next();
    }
  });
});

export default router;
