<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multiple" />
      <DxExport :enabled="true" />
      <DxColumn caption="Catetory">
        <DxColumn data-field="catCode" caption="Code" />
        <DxColumn data-field="catDescKor" caption="Kor" />
        <DxColumn data-field="catDescEng" caption="Eng" />
      </DxColumn>
      <DxColumn caption="Account">
        <DxColumn data-field="acctCode" caption="Code" />
        <DxColumn data-field="acctDescKor" caption="Kor" />
        <DxColumn data-field="acctDescEng" caption="Eng" />
      </DxColumn>
      <DxColumn data-field="fluctuation" caption="Fluctuation" />
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxExport,
  DxHeaderFilter,
  DxSearchPanel,
} from "devextreme-vue/data-grid";
import dsu from "../../utils/dataSourceUtils";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxSearchPanel,
  },
  data() {
    return {
      gridRefName: "grid",
      dataSource: dsu.getComDSGrid("/api/URAT", "acctCode", "", "Account Code"),
    };
  },
  methods: {
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 140px);
}
</style>
