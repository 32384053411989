<template>
  <div class="login_form_container">
    <form id="from" class="login-form" @submit.prevent="onSubmit()">
      <DxForm :form-data="formData" :disabled="loading" :ref="formRefName">
        <DxItem
          data-field='current'
          editor-type='dxTextBox'
          :editor-options="{ stylingMode: 'filled', placeholder: 'Current Password', mode: 'password' }">
          <DxRequiredRule message="Current password is required" />
          <DxLabel :visible="false"/>
        </DxItem>

        <DxItem
          data-field='new'
          editor-type='dxTextBox'
          :editor-options="{ stylingMode: 'filled', placeholder: 'New Password', mode: 'password' }">
          <DxRequiredRule message="새 비밀번호 필수 입력" />
          <DxLabel :visible="false"/>
          <DxPatternRule
          pattern="(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{2,50}).{8,14}$"  
          message="Password must be entered in 8 to 14 characters using a combination of letters, numbers, and special characters."/>  
        </DxItem>

        <DxItem
          data-field='newAgain'
          editor-type='dxTextBox'
          :editor-options="{ stylingMode: 'filled', placeholder: 'New Password Confirm', mode: 'password' }">
          <DxRequiredRule message="새 비밀번호 확인 필수 입력" />
          <DxPatternRule
          pattern="(?=.*\d{1,50})(?=.*[~`!@#$%\^&*()-+=]{1,50})(?=.*[a-zA-Z]{2,50}).{8,14}$" 
          message="Password must be entered in 8 to 14 characters using a combination of letters, numbers, and special characters."/>  
          <DxLabel :visible="false"/>
        </DxItem>
    
        <DxButtonItem>
          <DxButtonOptions
            width="100%"
            type="default"
            template="signInTemplate"
            :use-submit-behavior="true">
          </DxButtonOptions>
        </DxButtonItem>
        <template #signInTemplate>
          <div>
            <span class="dx-button-text">
              <dx-load-indicator v-if="loading" width="24px" height="24px" :visible="true" />
              <span v-if="!loading">Change Password</span>
            </span>
          </div>
        </template>
      </DxForm>
    </form>
  </div>
</template>

<script>
import auth from "../auth";
import fn from "../utils/function";
import DxLoadIndicator from "devextreme-vue/load-indicator";
import DxForm, {
  DxItem,
  DxRequiredRule,
  DxLabel,
  DxButtonItem,
  DxButtonOptions,
  DxPatternRule
} from "devextreme-vue/form";


export default {
  data() {
    return {
      formData: {},
      loading: false,
      formRefName: 'form'
    };
  },
  methods: {
    onSubmit: async function() {
    
    if(this.formData.new != this.formData.newAgain){
      fn.notifyError('새 비밀번호, 새 비밀번호 확인이 불일치');
      return;
    }

    let validationResult =  this.form.validate();
    if( validationResult.status != 'valid'){
      return;
    }

    this.loading = true;

      await auth.changePassword(this.formData)
                .then( result => {
                                    if(result) {
                                        this.$router.push("/mes/home");
                                    }
                                    this.loading = false;
                                  }
                      );
     }
  },
  computed: {
      form: function () {
        return this.$refs[this.formRefName].instance;
      }
  },
  components: {
    DxLoadIndicator,
    DxForm,
    DxRequiredRule,
    DxItem,
    DxLabel,
    DxButtonItem,
    DxButtonOptions,
    DxPatternRule
  }
}
</script>

<style lang="scss">
@import "../themes/generated/variables.base.scss";

#from{
  width: 400px;
  display: inline-block;
  background-color: white;
  padding: 20px;
}

.login_form_container{
  text-align: center;
  margin-top: 30px;
}

.login-form {
  .link {
    text-align: center;
    font-size: 16px;
    font-style: normal;

    a {
      text-decoration: none;
    }
  }

  .form-text {
    margin: 10px 0;
    color: rgba($base-text-color, alpha($base-text-color) * 0.7);
  }

}


</style>
