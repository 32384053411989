<template>
  <DxDataGrid
    id="grid"
    :ref="gridRefName"
    :dataSource="dataSource"
    :show-borders="true"
    :show-column-lines="true"
    :columnAutoWidth="true"
    :allowColumnResizing="true"
    @cell-prepared="onCellPrepared"
    @cell-click="onCellCkick"
    @exporting="onExporting"
  >
    <DxSearchPanel :visible="true" />
    <DxHeaderFilter :visible="true" />
    <DxScrolling mode="virtual" />
    <DxSorting mode="multiple" />
    <DxExport :enabled="true" />

    <DxColumn
      data-field="type"
      caption="구분"
      :header-filter="{ allowSearch: true }"
    >
    </DxColumn>

    <DxColumn
      data-field="code"
      caption="CODE"
      :header-filter="{ allowSearch: true }"
    >
    </DxColumn>

    <DxColumn
      data-field="material"
      caption="MATERIAL"
      :header-filter="{ allowSearch: true }"
    >
    </DxColumn>

    <DxColumn
      data-field="itemName"
      caption="ITEM_NAME"
      :header-filter="{ allowSearch: true }"
    >
    </DxColumn>

    <DxColumn
      data-field="location"
      caption="LOCATION"
      :header-filter="{ allowSearch: true }"
    >
    </DxColumn>

    <DxColumn
      data-field="custName"
      caption="CUST_NAME"
      :header-filter="{ allowSearch: true }"
    >
    </DxColumn>

    <DxColumn data-field="deliDate" caption="DELI_DATE" :allowFiltering="false">
    </DxColumn>

    <DxColumn data-field="srdDate" caption="SRD_DATE" :allowFiltering="false">
    </DxColumn>

    <DxColumn
      data-field="SOrder"
      caption="S_ORDER"
      dataType="number"
      format="###,###,###,###,###"
      :allowFiltering="false"
    >
    </DxColumn>

    <DxColumn
      data-field="amount"
      caption="AMOUNT"
      dataType="number"
      format="###,###,###,###,###"
      :allowFiltering="false"
    >
    </DxColumn>

    <DxColumn
      data-field="sim1"
      caption="SIM1"
      dataType="number"
      format="###,###,###,###,###"
      :allowFiltering="false"
    >
    </DxColumn>

    <DxColumn
      data-field="sim2"
      caption="SIM2"
      dataType="number"
      format="###,###,###,###,###"
      :allowFiltering="false"
    >
    </DxColumn>

    <DxColumn
      data-field="sim3"
      caption="SIM3"
      dataType="number"
      format="###,###,###,###,###"
      :allowFiltering="false"
    >
    </DxColumn>

    <DxColumn
      data-field="poNo"
      caption="PO_NO"
      :header-filter="{ allowSearch: true }"
    >
    </DxColumn>

    <DxColumn
      data-field="soNo"
      caption="SO_NO"
      :header-filter="{ allowSearch: true }"
    >
    </DxColumn>

    <DxColumn
      data-field="searchKeyword"
      caption="SEARCH_KEYWORD"
      :allowFiltering="false"
    >
    </DxColumn>

    <DxColumn
      data-field="dataSource"
      caption="DATA_SOURCE"
      :allowFiltering="false"
    >
    </DxColumn>

    <DxColumn
      data-field="salesPerson"
      caption="Sales Person"
      :allowFiltering="true"
    >
    </DxColumn>

    <DxColumn
      data-field="group2Name"
      caption="Customer/Vendor Group 2 Name"
      :allowFiltering="true"
    >
    </DxColumn>1

    <DxColumn
      data-field="application"
      caption="Application"
      :allowFiltering="faltruese"
    >
    </DxColumn>

    <DxColumn data-field="product" caption="Product" :allowFiltering="true">
    </DxColumn>
  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid,
  DxScrolling,
  DxColumn,
  DxSorting,
  DxSearchPanel,
  DxHeaderFilter,
  DxExport,
} from "devextreme-vue/data-grid";
// import { Workbook } from "exceljs";
// import { saveAs } from "file-saver-es";
// import { exportDataGrid } from "devextreme/excel_exporter";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxSorting,
    DxSearchPanel,
    DxHeaderFilter,
    DxExport,
  },
  data() {
    return {
      gridRefName: "grid",
      dataSource: [],
    };
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
  },
  methods: {
    refreshData(params, scd) {
      this.dataSource = [];

      if (params.group2Name) {
        var tmpData1 = [];
        tmpData1.push(params.group2Name);
        params.group2Name = JSON.stringify(tmpData1);
      }

      if (!params.group2Name) {
        params.group2Name = scd.group2Name;
      }

      if (params.itemName) {
        var tmpData2 = [];
        tmpData2.push(params.itemName);
        params.itemName = JSON.stringify(tmpData2);
      }

      if (!params.itemName) {
        params.itemName = scd.itemName;
      }

      params.custName = scd.custName;
      params.location = scd.location;
      params.salesPerson = scd.salesPerson;
      params.application = scd.application;
      params.product = scd.product;

      this.grid.beginCustomLoading("Data Loading...");
      dsu.getAxiosOBJPost("/api/UDLM/selectDCRawData", params).then((res) => {
        let response = res.data;
        this.grid.endCustomLoading();
        if (response.result == "SUCCESS") {
          this.dataSource = response.data;
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
         onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
    // onExporting(e) {
    //   const workbook = new Workbook();
    //   const worksheet = workbook.addWorksheet("Delivery Date Cordination");

    //   exportDataGrid({
    //     component: e.component,
    //     worksheet,
    //     keepColumnWidths: false,
    //     customizeCell: ({ gridCell, excelCell }) => {
    //       const rowType = gridCell.rowType;
    //       const dataField = gridCell.column.dataField;
    //       const dataType = gridCell.column.dataType;
    //       const rowData = gridCell.data;
    //       const value = gridCell.value;

    //       if (rowType == "data" && dataType == "number") {
    //         excelCell.numFmt = "###,###,###,###,###";
    //       }

    //       var bgColor = this.getCellColor(rowType, dataField, rowData);
    //       if (bgColor) {
    //         excelCell.fill = {
    //           type: "pattern",
    //           pattern: "darkVertical",
    //           fgColor: { argb: "FF" + bgColor },
    //           bgColor: { argb: "FF" + bgColor },
    //         };
    //       }

    //       var fontStyle = {};

    //       fontStyle.bold = this.getFontWeight(
    //         rowType,
    //         dataField,
    //         rowData,
    //         value
    //       );

    //       var fontColor = this.getFontColor(rowType, dataField, rowData, value);
    //       if (fontColor) {
    //         fontStyle.color = { argb: "FF" + fontColor };
    //       }

    //       if (fontStyle) {
    //         excelCell.font = fontStyle;
    //       }
    //     },
    //   }).then(() => {
    //     workbook.xlsx.writeBuffer().then((buffer) => {
    //       saveAs(
    //         new Blob([buffer], { type: "application/octet-stream" }),
    //         this.$route.meta.transName + ".xlsx"
    //       );
    //     });
    //   });
    //   e.cancel = true;
    // },
    getFontColor(rowType, dataField, rowData, value) {
      var fontColor = "";
      const colorMinus = "FA0000";
      const colorIdx3 = "34B2FA";

      if (rowType == "data") {
        if (rowData.idx == "2") {
          if (
            dataField == "sim1" ||
            dataField == "sim2" ||
            dataField == "sim3"
          ) {
            if (value < 0) {
              fontColor = colorMinus;
            }
          }
        } else if (rowData.idx == "3") {
          if (dataField == "sim1") {
            fontColor = colorIdx3;
          }
        }
      }

      return fontColor;
    },
    getFontWeight(rowType, dataField, rowData, value) {
      var fontWeight = false;
      if (rowType == "data") {
        if (rowData.idx == "1") {
          fontWeight = true;
        } else if (rowData.idx == "2") {
          if (
            dataField == "sim1" ||
            dataField == "sim2" ||
            dataField == "sim3"
          ) {
            if (value < 0) {
              fontWeight = true;
            }
          }
        } else if (rowData.idx == "3") {
          if (dataField == "sim1") {
            fontWeight = true;
          }
        }
      }

      return fontWeight;
    },
    getCellColor(rowType, dataField, rowData) {
      const colorHeader = "E4EEF7";

      const colorSRDOver = "FA5940";
      const colorSRDWw1 = "FFCF57";
      const colorSRDWw2 = "FFFD67";
      const colorSRDWw3 = "A6D068";
      const colorSRDWw4 = "91B3FF";
      const colorSRDOthers = "F5EDFC";

      const colorIdx1 = "D0E1CC";
      const colorIdx1Order = "DFDF96";

      const colorIdx3Color = "EBEBEB";

      var color = "";

      if (rowType == "header") {
        if (dataField == "srdOverQty") {
          color = colorSRDOver;
        } else if (dataField == "srdWw1Qty") {
          color = colorSRDWw1;
        } else if (dataField == "srdWw2Qty") {
          color = colorSRDWw2;
        } else if (dataField == "srdWw3Qty") {
          color = colorSRDWw3;
        } else if (dataField == "srdWw4Qty") {
          color = colorSRDWw4;
        } else if (dataField == "srdOthersQty") {
          color = colorSRDOthers;
        } else {
          color = colorHeader;
        }
      } else if (rowType == "data") {
        if (rowData.idx == "1") {
          if (dataField == "POrder" || dataField == "SOrder") {
            color = colorIdx1Order;
          } else {
            color = colorIdx1;
          }
        } else if (rowData.idx == "2") {
          if (dataField == "srdDate") {
            let srdWw = rowData.srdWw;
            if (srdWw == "OVER") {
              color = colorSRDOver;
            } else if (srdWw == "WW1") {
              color = colorSRDWw1;
            } else if (srdWw == "WW2") {
              color = colorSRDWw2;
            } else if (srdWw == "WW3") {
              color = colorSRDWw3;
            } else if (srdWw == "WW4") {
              color = colorSRDWw4;
            } else if (srdWw == "OTHERS") {
              color = colorSRDOthers;
            }
          }
        } else if (rowData.idx == "3") {
          color = colorIdx3Color;
        }
      }
      return color;
    },
    onCellPrepared(e) {
      var bgColor = this.getCellColor(e.rowType, e.column.dataField, e.data);
      if (bgColor) {
        e.cellElement.bgColor = "#" + bgColor;
      }
      var fontBold = this.getFontWeight(
        e.rowType,
        e.column.dataField,
        e.data,
        e.value
      );
      if (fontBold) {
        e.cellElement.style.fontWeight = "bold";
      }

      var fontColor = this.getFontColor(
        e.rowType,
        e.column.dataField,
        e.data,
        e.value
      );
      if (fontColor) {
        e.cellElement.style.color = "#" + fontColor;
      }
    },
  },
};
</script>

<style scoped>
#grid {
  height: calc(100vh - 150px);
}
</style>
