<template>
    <tableau-viz
      v-if="tableauToken"
      id="tableauViz"
      src="https://prod-apnortheast-a.online.tableau.com/t/seowoosoft/views/n_Main/mobile"
      :token="tableauToken"
      :hide-tabs="true"
      :hide-edit-button="true"
      :hide-edit-in-desktop-button="true"
    >
    </tableau-viz>
</template>

<script>
import axios from "axios";
import store from "../../store";

export default {
  data() {
    return {
      tableauToken: "",
    };
  },

  mounted() {
    let token = store.getters.getToken;
    let params = {
      token: token,
      userId: store.getters.getUserId,
      updateExpireTime: "N",
    };

    axios
      .post("/loginTableau", "", {
        headers: { "Content-Type": "application/json; charset=UTF-8" },
        params: params,
      })
      .then((res) => {
        this.tableauToken = res.data.msg;
      });
  },
};
</script>

<style scoped>

</style>
