<template >
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @editorPreparing="onEditorPreparing($event)"
      @row-inserting="onRowInserting">
      
      <DxSearchPanel :visible="true"/>
      <DxHeaderFilter :visible="true"/>
      <DxScrolling mode="infinite"/>
      <DxSorting mode="multiple"/>
      <DxExport :enabled="true"/>
      <DxEditing :allow-updating="true"
                :allow-adding="true"
                :allow-deleting="true"
                mode="popup"/>
      
      <DxColumn type="buttons">
        <DxButton name="edit"/>
      </DxColumn>
      
      <DxColumn
        data-field="factoryCode"
        caption="Factory Code"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
            pattern="^[A-Za-z0-9-_]{1,10}$"
            message="영문 숫자 '-(대쉬)' '_(언더 바)' 로만 구성, 10자 까지 등록 가능"/>
      </DxColumn>

      <DxColumn
        data-field="factoryName"
        caption="Factory Name"
        :header-filter="{ allowSearch: true }">
        <DxRequiredRule/>
        <DxPatternRule
          pattern="^.{2,20}$"
          message="2~20자 까지 등록 가능"/>
      </DxColumn>
    
      <DxMasterDetail
        :enabled="true"
        template="master-detail"/>
        <template #master-detail="{ data }">
          <History :master-detail-data="data"
                  :url= "`/api/AFTR/selectHistory`"
                  :keyName="factoryCode"
                  :columns="historyColumns"/>
        </template>    
    </DxDataGrid>
  </div>
</template>

<script>
  import {DxDataGrid,
          DxColumn,
          DxScrolling,
          DxSorting,
          DxExport,
          DxHeaderFilter,
          DxEditing,
          DxSearchPanel,
          DxRequiredRule,
          DxPatternRule,
          DxMasterDetail} from "devextreme-vue/data-grid";
    
  import History from "../_common/SIMPLE_HISTORY";
  import dsu from "../../utils/dataSourceUtils";
  
  export default {
    components: {
      DxDataGrid,
      DxColumn,
      DxScrolling,
      DxSorting,
      DxExport,
      DxHeaderFilter,
      DxEditing,
      DxSearchPanel,
      DxRequiredRule,
      DxPatternRule,
      DxMasterDetail,
      History
    },
    data() {
      return {
        gridRefName: 'grid',
        dataSource: dsu.getComDSGrid('/api/AFTR', 'factoryCode', '', 'Factory Code'),
        historyColumns: [{dataField: "transTime", caption : "Update Time"},
                         {dataField: "userId", caption: "Name"},
                         {dataField: "factoryName", caption: "Factory Name"},
                         {dataField: "dml", caption: "Action"}]
      };
    },
    methods:{
      onExporting(e) {
          e.fileName = this.$route.meta.transName;
      },
      onEditorPreparing: function (e) { 
        if (e.dataField == 'factoryCode'){
          e.editorOptions.inputAttr = {'style':  "text-transform: uppercase"};
        }
      },
      onRowInserting(e){
        e.data.factoryCode = e.data.factoryCode.toUpperCase();
      }
    }
  };

</script>

<style scoped>
  #grid{
    max-height: calc(100vh - 210px);
  }
</style>