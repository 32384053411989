<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
        id="grid"
        :ref="gridRefName"
        :data-source="cat1List"
        :show-borders="true"
        :show-column-lines="true"
        :column-auto-width="true"
        :focused-row-enabled="true"
        key-expr="category"
        @row-inserting="onRowInserting"
        @row-removing="onRowRemoving"
        @exporting="onExporting"
        @toolbar-preparing="onToolbarPreparing($event)"
        @focused-row-changed="selectedRowChanged($event)">

        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        <DxEditing :allow-adding="true"
                   :allow-updating="false"
                   :allow-deleting="true"
                   mode="cell"/>
        <DxExport :enabled="true"/>

        <DxColumn type="buttons">
          <DxButton name="edit"/>
        </DxColumn>
        
        <DxColumn
          data-field="category"
          caption="Category">
          <DxRequiredRule/>
          <DxPatternRule
            pattern="^.{1,50}$"
            message="50자 까지 등록 가능"/>
        </DxColumn>

        <DxMasterDetail
          :enabled="true"
          template="master-detail"/>
          <template #master-detail="{ data }">
            <History :master-detail-data="{data}"
                    :url= "`/api/ACLI/selectCheckListCatInfoHst`"
                    :keyName="category"
                    :key1="data.data.category"
                    :key2="checkListId"
                    :columns="historyColumns"/>
          </template> 
        
        <template #gridSubjectTemplate>
          <div v-if="cat1Name" class="gridSubject">{{cat1Name}} List</div>
        </template>
    </DxDataGrid>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
        id="grid"
        :ref="gridRefName"
        :data-source="cat2List"
        :show-borders="true"
        :show-column-lines="true"
        :column-auto-width="true"
        :focused-row-enabled="true"
        key-expr="category"
        @row-inserting="onRowInsertingCat2"
        @row-removing="onRowRemovingCat2"
        @exporting="onExportingCat2List"
        @toolbar-preparing="onToolbarPreparing($event)">

        <DxSearchPanel :visible="true"/>
        <DxHeaderFilter :visible="true"/>
        <DxScrolling mode="infinite"/>
        <DxSorting mode="multiple"/>
        <DxEditing :allow-adding="true"
                   :allow-updating="false"
                   :allow-deleting="true"
                   mode="cell"/>
        <DxExport :enabled="true"/>

        <DxColumn type="buttons">
          <DxButton name="edit"/>
        </DxColumn>
        
        <DxColumn
          data-field="category"
          caption="Category">
          <DxRequiredRule/>
          <DxPatternRule
            pattern="^.{1,50}$"
            message="50자 까지 등록 가능"/>
        </DxColumn>
        
        <DxMasterDetail
          :enabled="true"
          template="master-detail"/>
          <template #master-detail="{ data }">
            <History :master-detail-data="{data}"
                    :url= "`/api/ACLI/selectCheckListCat2InfoHst`"
                    :keyName="category"
                    :key1="data.data.category"
                    :key2="checkListId"
                    :key3="selCat1"
                    :columns="historyColumns"/>
          </template> 
        
     
        <template #gridSubjectTemplate>
          <div v-if="cat2Name" class="gridSubject">{{cat2Name}} List</div>
        </template>
    </DxDataGrid>
  </div>  
</template>

<script>
  import {DxDataGrid,
          DxScrolling,
          DxColumn,
          DxSorting,
          DxSearchPanel,
          DxHeaderFilter,
          DxRequiredRule,
          DxPatternRule,
          DxEditing,
          DxExport,
          DxMasterDetail} from "devextreme-vue/data-grid";
  import History from "../_common/SIMPLE_HISTORY";
  import dsu from "../../utils/dataSourceUtils";
  import fn from "../../utils/function";
  
  export default {
    components: {
      DxDataGrid,
      DxScrolling,
      DxColumn,
      DxSorting,
      DxSearchPanel,
      DxHeaderFilter,
      DxRequiredRule,
      DxPatternRule,
      DxEditing,
      DxExport,
      DxMasterDetail,
      History
    },
    props: {
      checkListId: String,
      cat1Name: String,
      cat2Name: String
    },
    data() {
      return {
        gridRefName: 'dataGrid',
        cat1List: [],
        cat2List: [],
        selCat1: '',
        onToolbarPreparing(e) {
          e.toolbarOptions.items.unshift(
            {location: 'before',
             locateInMenu: 'auto',
             template: 'gridSubjectTemplate'}
          );
        },
        historyColumns: [{dataField : "transTime", caption : "Update Time"},
                         {dataField: "category", caption: "Category"},
                         {dataField: "dmlUserId", caption: "Update User"},
                         {dataField: "dml", caption: "Action"}]
      };
    },
    computed: {
        grid: function() {return this.$refs[this.gridRefName].instance;}
    },
    methods:{
      refreshData(){
        this.cat1List = [];
        this.cat2List = [];

        var params = {id: this.checkListId}

        dsu.getAxiosOBJGet('/api/ACLI/selectCheckListCatInfo', params).then(res => {
          this.cat1List = res.data.data;
        });
      },
      onRowInserting(e){
        if(!this.checkListId){
          fn.notifyError("Check List 선택 후 진행 필요");
          this.cat1List = [];
          return;
        }
        
        e.data.id = this.checkListId;

        dsu.getAxiosOBJPost('/api/ACLI/insertCheckListCatInfo', e.data).then(res => {
            let response = res.data;
            this.refreshData();      
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
              e.cancel = true;
            }
        });
      },
      onRowRemoving(e){
        e.data.id = this.checkListId;
        
        dsu.getAxiosOBJPost('/api/ACLI/deleteCheckListCatInfo', e.data).then(res => {
            let response = res.data;
            this.refreshData();
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
              e.cancel = true;
            }
        });
      },
      onExporting(e) {
          e.fileName = this.cat1Name + ' List';
      },
      onExportingCat2List(e) {
          e.fileName = this.cat2Name + ' List';
      },
      selectedRowChanged(e){
        this.selCat1 = e.row.data.category;
        this.refreshData2();
      },
      refreshData2(){
        var params = {id: this.checkListId,
                      parentCategory: this.selCat1}

        dsu.getAxiosOBJGet('/api/ACLI/selectCheckListCat2Info', params).then(res => {
          this.cat2List = res.data.data;
        });
      },
      onRowInsertingCat2(e){
        if(!this.checkListId || !this.selCat1 ){
          fn.notifyError(this.cat1Name + " 선택 후 진행 필요");
          this.cat2List = [];
          return;
        }
        
        e.data.id = this.checkListId;
        e.data.parentCategory = this.selCat1;

        dsu.getAxiosOBJPost('/api/ACLI/insertCheckListCat2Info', e.data).then(res => {
            let response = res.data;
            this.refreshData2();
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
              e.cancel = true;
            }
        });
      },
      onRowRemovingCat2(e){
        e.data.id = this.checkListId;
        e.data.parentCategory = this.selCat1;
        
        dsu.getAxiosOBJPost('/api/ACLI/deleteCheckListCat2Info', e.data).then(res => {
            let response = res.data;
            this.refreshData2();
            if(response.result == 'SUCCESS'){
              fn.notifySuccess(response.msg);
            }else {
              fn.notifyError(response.msg);
              e.cancel = true;
            }
        });
      },
    },
    watch: {
      checkListId() {
        this.refreshData();
      }
    }
  };
</script>

<style scoped>
  #grid{
    max-height: 400px !important;
  }
</style>