import store from './store';

export default {

  getMenuItems() {
    const menuList = JSON.parse(store.getters.getMenuList);
    // const userId = store.getters.getUserId;
    let prvGroupSeq = 0;
    let menuItem = [
      {
        text: "Short Cut",
        icon: "home",
        items: [{
          text: "HOME",
          path: "/home"
        },
          // {
          //   text: "Manual",
          //   path: "/manual"
          //   }
        ]
      }
    ];

    for (var i = 0; i < menuList.length; i++) {
      if (prvGroupSeq != menuList[i].groupSeq) {
        prvGroupSeq = menuList[i].groupSeq;
        var tmpMenuSubItem = menuList.filter((item) => (item.groupSeq == menuList[i].groupSeq));
        var menuSubItem = [];
        for (var j = 0; j < tmpMenuSubItem.length; j++) {
          menuSubItem.push({ text: tmpMenuSubItem[j].menuName, path: tmpMenuSubItem[j].path });
        }

        // if (userId == "ADMIN") {
        //   if (menuList[i].groupSeq == 2) {
        //     menuSubItem.push({ text: "Forcast  T", path: "/ubbs" });
        //   }
        // }

        menuItem.push({
          text: menuList[i].groupName,
          icon: menuList[i].icon,
          items: menuSubItem
        });
      }
    }
    return menuItem;
  }
}