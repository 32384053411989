<template>
  <DxDataGrid
    id="grid"
    :ref="gridRefName"
    :dataSource="checkListItemData"
    :columnAutoWidth="true"
    :show-borders="true"
    :show-column-lines="true"
    :allow-column-resizing="true"
    height="100vh"
    @toolbar-preparing="onToolbarPreparing($event)"
    @cell-prepared="onCellPrepared"
    @rowUpdating="onRowUpdating"
    @rowUpdated="onRowUpdated"
    @cellClick="onCellClick"
    @exporting="onExporting"
  >
    <DxSorting mode="none" />
    <DxScrolling mode="infinite" />
    <DxEditing mode="cell" :allowUpdating="true" start-edit-action="click" />
    <DxExport :enabled="true" />

    <DxColumn
      data-field="num"
      caption="Sequence"
      :minWidth="80"
      :allowEditing="false"
    >
    </DxColumn>

    <DxColumn data-field="type" caption="Type" :allowEditing="false">
    </DxColumn>

    <DxColumn
      data-field="checkPoint"
      caption="Check Point"
      :minWidth="95"
      :allowEditing="false"
    >
    </DxColumn>

    <DxColumn
      data-field="item"
      caption="Check Info"
      cell-template="text-area-template"
      :allowEditing="false"
    >
    </DxColumn>

    <DxColumn
      data-field="score"
      caption="Points"
      :minWidth="70"
      :allowEditing="false"
    >
    </DxColumn>

    <DxColumn caption="The Person in Charge">
      <DxColumn
        data-field="scoreUser"
        caption="Evaluation"
        dataType="number"
        :editorOptions="{ min: 0 }"
        :minWidth="90"
        :allow-editing="visibleUser"
      >
      </DxColumn>

      <DxColumn
        data-field="inputUserName"
        caption="name"
        :minWidth="75"
        :allowEditing="false"
      >
      </DxColumn>

      <DxColumn
        data-field="inputUserId"
        caption="ID"
        :visible="false"
        :allowEditing="false"
      >
      </DxColumn>
    </DxColumn>

    <DxColumn caption="Manager">
      <DxColumn
        data-field="scoreAdmin"
        caption="Evaluation"
        dataType="number"
        :editorOptions="{ min: 0 }"
        :minWidth="90"
        :allow-editing="visibleAdmin"
      >
      </DxColumn>

      <DxColumn
        data-field="inputAdminName"
        caption="name"
        :minWidth="75"
        :allowEditing="false"
      >
      </DxColumn>

      <DxColumn
        data-field="inputAdminId"
        caption="ID"
        :visible="false"
        :allowEditing="false"
      >
      </DxColumn>
    </DxColumn>

    <DxColumn
      data-field="failScore"
      caption="Fail Criterion"
      :minWidth="100"
      :allowEditing="false"
    >
    </DxColumn>

    <DxColumn
      data-field="importantPoint"
      caption=" Important Point"
      cell-template="text-area-template"
      :allowEditing="false"
    >
    </DxColumn>

    <DxColumn
      data-field="specialNote"
      caption="Remark"
      cell-template="text-area-template"
      :allowEditing="isEditingMode"
      :minWidth="300"
    >
    </DxColumn>

    <template #text-area-template="{ data }">
      <pre>{{ data.value }}</pre>
    </template>

    <template #userScoreSumTemplate>
      <div class="gridSubject">
        Score of The person in charge : {{ userScoreSum }}
      </div>
    </template>

    <template #dummyTemplate>
      <div class="gridSubject">Manager Score : {{ adminScoreSum }}</div>
    </template>

    <template #adminScoreSumTemplate>
      <div class="gridSubject">Manager Score : {{ adminScoreSum }}</div>
    </template>
  </DxDataGrid>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxSorting,
  DxScrolling,
  DxEditing,
  DxExport,
} from "devextreme-vue/data-grid";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import store from "../../store";
import Query from "devextreme/data/query";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxSorting,
    DxScrolling,
    DxEditing,
    DxExport,
  },
  props: {
    selectedCheckListData: Object,
    isEditingMode: Boolean,
  },
  data() {
    return {
      gridRefName: "grd",
      checkListItemData: [],
      popupScordRefName: "popup",
      score: "",
      currentRow: {},
      scoreRefName: "score",
      maxVal: 5,
      visibleUser: false,
      visibleAdmin: false,
      userScoreSum: null,
      adminScoreSum: null,
    };
  },
  watch: {
    selectedCheckListData: {
      deep: true,
      handler() {
        this.refreshData();
      },
    },
  },
  mounted() {
    const menuAuth = JSON.parse(store.getters.getMenuList).find(
      (item) => item.transaction === "UCLR"
    );
    if (this.isEditingMode) {
      if (menuAuth.f1Auth == "false") {
        this.visibleUser = true;
        this.visibleAdmin = false;
      } else {
        this.visibleUser = false;
        this.visibleAdmin = true;
      }
    } else {
      this.visibleUser = false;
      this.visibleAdmin = false;
    }
  },
  methods: {
    refreshData() {
      dsu
        .getAxiosOBJGet(
          "/api/UCLR/selectCheckListItemData",
          this.selectedCheckListData
        )
        .then((res) => {
          let data = res.data.data;
          if (res.data.result == "SUCCESS") {
            this.checkListItemData = data;

            if (data.length == 0 && this.isEditingMode) {
              fn.notifyError("Check item 정보 등록 후 진행 필요");
            }

            this.onRowUpdated();
          } else {
            fn.notifyError(res.data.msg);
          }
        });
    },
    setScore(e) {
      this.score = e.value;
    },
    onToolbarPreparing(e) {
      if (this.isEditingMode) {
        e.toolbarOptions.items.unshift(
          {
            location: "before",
            locateInMenu: "auto",
            template: "userScoreSumTemplate",
          },
          {
            location: "before",
            locateInMenu: "auto",
            template: "adminScoreSumTemplate",
          },
          {
            location: "after",
            locateInMenu: "auto",
            widget: "dxButton",
            options: {
              text: "Save",
              icon: "save",
              stylingMode: "outlined",
              onClick: this.save.bind(this),
            },
          },
          {
            location: "after",
            locateInMenu: "auto",
            widget: "dxButton",
            options: {
              text: "Delete",
              icon: "remove",
              stylingMode: "outlined",
              onClick: this.delete.bind(this),
            },
          }
        );
      }
    },
    save(e) {
      this.$refs[this.gridRefName].instance.saveEditData();

      if (this.checkListItemData.length < 1) {
        fn.notifyError("Check List Load 후 진행 필요");
        return;
      }

      var nullScoreAdmin = Query(this.checkListItemData)
        .filter(["scoreAdmin", "=", null])
        .toArray();

      var nullScoreUser = Query(this.checkListItemData)
        .filter(["scoreUser", "=", null])
        .toArray();

      if (
        nullScoreAdmin.length == this.checkListItemData.length &&
        nullScoreUser.length == this.checkListItemData.length
      ) {
        fn.notifyError("최소 하나의 Evaluation Score 입력 후 진행 필요");
        return;
      }

      var params = {};
      Object.assign(params, this.selectedCheckListData);
      params.checkListItemData = JSON.stringify(this.checkListItemData);

      e.component.option("disabled", true);

      dsu
        .getAxiosOBJPost("/api/UCLR/updateCheckListItemData", params)
        .then((res) => {
          let response = res.data;
          if (response.result == "SUCCESS") {
            fn.notifySuccess(response.msg);
          } else {
            fn.notifyError(response.msg);
          }
          e.component.option("disabled", false);
        });
    },
    async delete(e) {
      if (this.checkListItemData.length < 1) {
        fn.notifyError("Check List Load 후 진행 필요");
        return;
      }

      if ((await fn.confirm("정말 삭제 하시겠습니까?")) == false) {
        return;
      }

      e.component.option("disabled", true);

      dsu
        .getAxiosOBJPost(
          "/api/UCLR/deleteCheckListItemData",
          this.selectedCheckListData
        )
        .then((res) => {
          let response = res.data;
          if (response.result == "SUCCESS") {
            fn.notifySuccess(response.msg);
            this.checkListItemData = [];
          } else {
            fn.notifyError(response.msg);
          }

          e.component.option("disabled", false);
        });
    },
    clear() {
      this.checkListItemData = [];
    },
    onCellPrepared(e) {
      if (e.rowType == "data") {
        var dataField = e.column.dataField;
        var dataRow = e.data;
        var data = e.data[dataField];

        if (dataField) {
          if (e.data.style == "GREEN") {
            e.cellElement.bgColor = "#e4efdc";
          } else if (e.data.style == "RED") {
            e.cellElement.bgColor = "#fad4d4";
          }

          if (dataField == "scoreUser" || dataField == "scoreAdmin") {
            if (data == 0 || data) {
              if (data < dataRow.failScore) {
                e.cellElement.bgColor = "#F6C7B3";
              } else {
                e.cellElement.bgColor = "#f7f5f5";
              }
            } else {
              e.cellElement.bgColor = "#f7f5f5";
            }
          }

          if (dataField == "specialNote") {
            e.cellElement.bgColor = "#f7f5f5";
          }
        }
      }
    },
    onRowUpdating(e) {
      let auth = store.getters.getAuth;

      if (e.newData.specialNote) {
        return;
      }

      if (auth == "USER") {
        if (e.newData.scoreUser) {
          e.newData.inputUserId = store.getters.getUserId;
          e.newData.inputUserName = store.getters.getUserName;
        } else {
          e.newData.inputUserId = "";
          e.newData.inputUserName = "";
        }

        if (e.oldData.score < e.newData.scoreUser) {
          e.newData.scoreUser = e.oldData.score;
        }
        if (e.newData.scoreUser % 0.5 > 0) {
          fn.notifyError("Score는 0.5 단위로 입력 가능");
          e.newData.scoreUser = e.oldData.scoreUser;
        }
      } else {
        if (e.newData.scoreAdmin) {
          e.newData.inputAdminId = store.getters.getUserId;
          e.newData.inputAdminName = store.getters.getUserName;
        } else {
          e.newData.inputAdminId = "";
          e.newData.inputAdminName = "";
        }

        if (e.oldData.score < e.newData.scoreAdmin) {
          e.newData.scoreAdmin = e.oldData.score;
        }
        if (e.newData.scoreAdmin % 0.5 > 0) {
          fn.notifyError("Score는 0.5 단위로 입력 가능");
          e.newData.scoreAdmin = e.oldData.scoreAdmin;
        }
      }
    },
    onRowUpdated() {
      let scoreUser = 0;
      let scoreAdmin = 0;

      for (let i = 0; i < this.checkListItemData.length; i++) {
        scoreUser += this.checkListItemData[i].scoreUser;
        scoreAdmin += this.checkListItemData[i].scoreAdmin;
      }

      this.userScoreSum = scoreUser;
      this.adminScoreSum = scoreAdmin;
    },
    onCellClick(e) {
      let auth = store.getters.getAuth;

      if (this.sEditingMode) {
        if (auth == "USER") {
          if (e.column.name == "scoreUser") {
            this.$refs[this.gridRefName].instance.editCell(
              e.rowIndex,
              "scoreUser"
            );
          }
        } else {
          if (e.column.name == "scoreAdmin") {
            this.$refs[this.gridRefName].instance.editCell(
              e.rowIndex,
              "scoreAdmin"
            );
          }
        }
      }
    },
    onExporting(e) {
      e.fileName = "체크리스트 입력 데이터";
    },
  },
};
</script>

<style scoped>
.gridSubject {
  padding-right: 20px !important;
}
</style>
