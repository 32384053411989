<template>
  <tableau-viz
    v-if="showTableau"
    id="tableauViz"
    :src="tableauUrl"
    :token="tableauToken"
    :hide-tabs="true"
    :hide-edit-button="true"
    :hide-edit-in-desktop-button="true"
  >
  </tableau-viz>

<div v-if="errorMsg" class='error-content-conatiner'>
    <div class='error-code'> 
      400
    </div>
    <div class='error-desc'>
        {{errorMsg}}
    </div>
</div>
</template>

<script>
import axios from "axios";

export default {
    data() {
        return {
            tableauToken: "",
            tableauUrl: "",
            errorMsg: "",
            showTableau: false,
        };
    },

    mounted() {
        if (this.$route.query.z && this.$route.query.i) {
            var params = {
                z: this.$route.query.z,
                i: this.$route.query.i
            };
            axios.post("/loginTableauApp", "", {
                headers: { "Content-Type": "application/json; charset=UTF-8" },
                params: params,
            }).then((res) => {
                if (res.data.result == "SUCCESS") {
                    this.tableauUrl = res.data.data.url;
                    this.tableauToken = res.data.data.token;    
                    this.showTableau = true;
                }
                else {
                    this.errorMsg = res.data.msg;
                }
            });
        } else {
            this.errorMsg = "Bad URL"; 
        }
    },
};
</script>

<style lang="scss">
  .error-content-conatiner {
    margin-top: 100px;
    width: 100%;
    height: 100%;
    margin: 50px 0 40px 0;
    text-align: center;

    .error-code{
      font-size: 70px;
      font-weight: 450;
    }

    .error-desc{
      font-size: 30px;
      font-weight: 400;
    }    
  }
</style>