<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formData"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />

      <DxSimpleItem
        data-field="startDate"
        editor-type="dxDateBox"
        :editor-options="{
          displayFormat: 'yyyy/MM/dd',
        }"
      >
        <DxLabel text="Start Date" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="endDate"
        editor-type="dxDateBox"
        :editor-options="{
          displayFormat: 'yyyy/MM/dd',
        }"
      >
        <DxLabel text="End Date" />
        <DxRequiredRule />
      </DxSimpleItem>
      <DxButtonItem
        horizontal-alignment="center"
        vertical-alignment="center"
        :buttonOptions="{
          text: 'Search',
          icon: 'search',
          onClick: searchData,
        }"
      />
    </DxForm>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multiple" />
      <DxExport :enabled="true" />
      <DxColumn data-field="dt" caption="Date" />
      <DxColumn data-field="currency" caption="Currency" />
      <DxColumn data-field="nation" caption="Nation" />
      <DxColumn data-field="won" caption="Won(₩)" format="###,###,###,##0.00" />
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxRequiredRule,
  DxButtonItem,
} from "devextreme-vue/form";

import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxExport,
  DxHeaderFilter,
  DxSearchPanel,
} from "devextreme-vue/data-grid";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

var endDate = new Date();
var startDate = new Date();
startDate.setDate(endDate.getDate() - 7);
var defaultStartDate = startDate;
var defaultEndDate = endDate;

export default {
  components: {
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxSearchPanel,
    DxButtonItem,
  },
  data() {
    return {
      searchFormRefName: "searchForm",
      formData: { startDate: defaultStartDate, endDate: defaultEndDate },
      gridRefName: "grid",
      dataSource: [],
    };
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
  },
  methods: {
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    searchData(e) {
      this.gridData = [];
      var params = {
        startDate: dsu.getFormatDate(this.formData.startDate),
        endDate: dsu.getFormatDate(this.formData.endDate),
      };

      this.grid.beginCustomLoading("Data Loading...");
      if (e) {
        e.component.option("disabled", true);
      }
      dsu.getAxiosOBJGet("/api/UEXR/selectExchangeRate", params).then((res) => {
        let response = res.data;
        this.grid.endCustomLoading();
        e.component.option("disabled", false);
        if (response.result == "SUCCESS") {
          this.dataSource = response.data;
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 250px);
}
</style>
