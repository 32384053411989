<template>
  <div
    v-show="visibleSearchCondition"
    class="content-block dx-card responsive-paddings"
  >
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formData"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />
      <DxSimpleItem
        data-field="custName"
        editorType="dxSelectBox"
        :editor-options="{
          items: customerList,
          valueExpr: 'code',
          displayExpr: 'name',
          searchEnabled: true,
          showClearButton: true,
        }"
      >
        <DxLabel text="CUST_NAME" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="item"
        editor-type="dxTextBox"
        :editorOptions="{
          placeholder: 'ITEM_CODE, ITEM_NAME, MATERIAL',
        }"
      >
        <DxLabel text="ITEM" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="locType"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: locTypeList,
          valueExpr: 'ID',
          displayExpr: 'Name',
        }"
      >
        <DxLabel text="SALES/FACTORY" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="dateType"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: dateTypeList,
          valueExpr: 'ID',
          displayExpr: 'Name',
        }"
      >
        <DxLabel text="DATE" />
      </DxSimpleItem>

      <DxButtonItem
        horizontal-alignment="center"
        vertical-alignment="center"
        :buttonOptions="{
          text: 'Search',
          icon: 'search',
          onClick: searchData,
        }"
      />
    </DxForm>
    <DxRadioGroup v-if="false" />
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="gridUSDC"
      :ref="gridRefName"
      :dataSource="gridData"
      :show-borders="true"
      :show-column-lines="true"
      :columnAutoWidth="true"
      :allowColumnResizing="true"
      @toolbar-preparing="onToolbarPreparing($event)"
      @cell-prepared="onCellPrepared"
      @cell-click="onCellCkick"
      @cell-dbl-click="onCellDblCkick"
      @exporting="onExporting"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" :useNative="true" />
      <DxSorting mode="none" />
      <DxExport :enabled="true" />

      <DxColumn
        data-field="viewUnstoredData"
        :caption="viewUnstoredStatus"
        :allowFiltering="false"
        :allow-exporting="false"
      >
      </DxColumn>

      <DxColumn
        data-field="type"
        caption="구분"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn data-field="idx" caption="IDX" :allowFiltering="false">
      </DxColumn>

      <DxColumn
        data-field="code"
        caption="CODE"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="material"
        caption="MATERIAL"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="itemName"
        caption="ITEM_NAME"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="location"
        caption="LOCATION"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="custName"
        caption="CUST_NAME"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="whStock"
        caption="WH_STOCK"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        data-field="trStock"
        caption="TR_STOCK"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        data-field="POrder"
        caption="P_ORDER"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        data-field="deliDate"
        caption="DELI_DATE"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn data-field="srdDate" caption="SRD_DATE" :header-filter="{ allowSearch: true }">
      </DxColumn>

      <DxColumn
        data-field="SOrder"
        caption="S_ORDER"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        data-field="amount"
        caption="AMOUNT"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="isSrdDate"
        data-field="sim1"
        caption="SIM1"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="isSrdDate"
        data-field="sim2"
        caption="SIM2"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="isSrdDate"
        data-field="sim3"
        caption="SIM3"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="!isSrdDate"
        data-field="sim1Deli"
        caption="SIM1"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="!isSrdDate"
        data-field="sim2Deli"
        caption="SIM2"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="!isSrdDate"
        data-field="sim3Deli"
        caption="SIM3"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        data-field="poNo"
        caption="PO_NO"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="poSeq"
        caption="PO_SEQ"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="soNo"
        caption="SO_NO"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        v-if="isSrdDate"
        data-field="srdOverQty"
        caption="OVER"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="isSrdDate"
        data-field="srdWw1Qty"
        caption="1W(7)"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="isSrdDate"
        data-field="srdWw2Qty"
        caption="2W(14)"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="isSrdDate"
        data-field="srdWw3Qty"
        caption="3W(21)"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="isSrdDate"
        data-field="srdWw4Qty"
        caption="4W(28)"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="isSrdDate"
        data-field="srdOthersQty"
        dataType="number"
        format="###,###,###,###,###"
        caption="OTHERS(Over 28)"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="!isSrdDate"
        data-field="deliOverQty"
        caption="OVER"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="!isSrdDate"
        data-field="deliWw1Qty"
        caption="1W(7)"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="!isSrdDate"
        data-field="deliWw2Qty"
        caption="2W(14)"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="!isSrdDate"
        data-field="deliWw3Qty"
        caption="3W(21)"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="!isSrdDate"
        data-field="deliWw4Qty"
        caption="4W(28)"
        dataType="number"
        format="###,###,###,###,###"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        v-if="!isSrdDate"
        data-field="deliOthersQty"
        dataType="number"
        format="###,###,###,###,###"
        caption="OTHERS(Over 28)"
        :allowFiltering="false"
      >
      </DxColumn>

      <DxColumn
        data-field="searchKeyword"
        caption="SEARCH_KEYWORD"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="dataSource"
        caption="DATA_SOURCE"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="salesPerson"
        caption="Sales Person"
        :allowFiltering="true"
      >
      </DxColumn>

      <DxColumn
        data-field="group2Name"
        caption="Customer/Vendor Group 2 Name"
        :allowFiltering="true"
      >
      </DxColumn>

      <DxColumn
        data-field="application"
        caption="Application"
        :allowFiltering="faltruese"
      >
      </DxColumn>

      <DxColumn data-field="product" caption="Product" :allowFiltering="true">
      </DxColumn>
    </DxDataGrid>
    <DxPopup
      :ref="popupRefName"
      width="820"
      height="auto"
      title="Modify SRD Date"
      :drag-enabled="true"
      :hideOnOutsideClick="true"
      :show-title="true"
    >
      <div class="content-block dx-card responsive-paddings">
        <DxForm
          :ref="modifySrdDateRefName"
          label-location="left"
          :form-data="formDataMSD"
        >
          <DxSimpleItem
            data-field="poNo"
            editor-type="dxTextBox"
            :editor-options="{ disabled: true }"
          >
            <DxLabel text="PO No" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="poSeq"
            editor-type="dxTextBox"
            :editor-options="{ disabled: true }"
          >
            <DxLabel text="PO Seq" />
          </DxSimpleItem>

          <DxSimpleItem
            data-field="srdDate"
            editor-type="dxDateBox"
            :editor-options="{ displayFormat: 'yyyy/MM/dd' }"
          >
            <DxLabel text="SRD Date" />
            <DxRequiredRule />
          </DxSimpleItem>
          <DxGroupItem :colCount="2">
            <DxButtonItem
              horizontal-alignment="right"
              vertical-alignment="center"
              :buttonOptions="{
                text: 'Save',
                icon: 'save',
                onClick: saveSrdDate,
              }"
            />

            <DxButtonItem
              horizontal-alignment="left"
              vertical-alignment="center"
              :buttonOptions="{
                text: 'Delete',
                icon: 'clear',
                onClick: deleteSrdDate,
              }"
            />
          </DxGroupItem>
        </DxForm>
      </div>
      <div class="content-block dx-card responsive-paddings">
        <DxDataGrid
          :ref="gridSRDDateHistoryRefName"
          :dataSource="srdDateHistoryList"
          :show-borders="true"
          :show-column-lines="true"
          :columnAutoWidth="true"
          :allowColumnResizing="true"
          height="300"
        >
          <DxColumn
            data-field="transTime"
            caption="Modify Time"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="dml"
            caption="Action"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="userId"
            caption="Modify User"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="poNo"
            caption="Po No"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="poSeq"
            caption="Po Seq"
            :header-filter="{ allowSearch: true }"
          />

          <DxColumn
            data-field="srdDate"
            caption="SRD Date"
            :header-filter="{ allowSearch: true }"
          />
        </DxDataGrid>
      </div>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxScrolling,
  DxColumn,
  DxSorting,
  DxSearchPanel,
  DxHeaderFilter,
  DxExport,
} from "devextreme-vue/data-grid";
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxRequiredRule,
  DxGroupItem,
} from "devextreme-vue/form";
import DxPopup from "devextreme-vue/popup";
import DxRadioGroup from "devextreme-vue/radio-group";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";
import Query from "devextreme/data/query";
// import { Workbook } from "exceljs";
// import { saveAs } from "file-saver-es";
// import { exportDataGrid } from "devextreme/excel_exporter";
import { locType, dateType } from "../../utils/syscodedata";

export default {
  components: {
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxSorting,
    DxSearchPanel,
    DxHeaderFilter,
    DxExport,
    DxRadioGroup,
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxPopup,
    DxRequiredRule,
    DxGroupItem,
  },
  data() {
    return {
      formData: { locType: "ALL", dateType: "SRD_DATE" },
      gridRefName: "grid",
      gridData: [],
      rawData: [],
      viewUnstoredStatus: "+",
      customerList: [],
      isSrdDate: true,
      visibleSearchCondition: true,
      dateTypeList: dateType,
      locTypeList: locType,
      srdDateNew: "",
      popupRefName: "popup",
      formDataMSD: {},
      gridSRDDateHistoryRefName: "gridSRDDate",
      srdDateHistoryList: [],
      modifySrdDateRefName: "srdModifyForm",
    };
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
    updateTime: function () {
      var updateTime = "";
      if (this.gridData.length > 0) {
        updateTime = this.gridData[0].transTime;
      }

      return updateTime;
    },
  },
  mounted() {
    dsu.getAxiosCustomerInfo().then((res) => {
      this.customerList = res.data.data;
    });
  },
  methods: {
    searchData(e) {
      this.gridData = [];
      var params = {};

      params.itemInfo = this.formData.item;
      params.location = this.formData.locType;
      params.custCode = this.formData.custName;
      params.dateType = this.formData.dateType;

      if (params.dateType == "SRD_DATE") {
        this.isSrdDate = true;
      } else {
        this.isSrdDate = false;
      }

      this.grid.beginCustomLoading("Data Loading...");
      if (e) {
        e.component.option("disabled", true);
      }
      dsu
        .getAxiosOBJPost("/api/USDC/selectDeliveryCordination", params)
        .then((res) => {
          this.grid.clearFilter();
          let response = res.data;
          this.grid.endCustomLoading();
          if (e) {
            e.component.option("disabled", false);
          }
          if (response.result == "SUCCESS") {
            this.rawData = response.data;
            this.gridData = Query(this.rawData)
              .filter(["idx", "<>", "3"])
              .toArray();
            this.viewUnstoredStatus = "+";
            this.toggleSearchBox();
          } else {
            fn.notifyError(response.msg);
          }
        });
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: "before",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            text: "Update Times",
            icon: "info",
            stylingMode: "outlined",
            onClick: this.showSummaryTimees.bind(this),
          },
        },
        {
          location: "after",
          locateInMenu: "auto",
          widget: "dxButton",
          options: {
            text: "Re-Summary",
            icon: "pulldown",
            stylingMode: "outlined",
            onClick: this.reSummary.bind(this),
          },
        },
        {
          location: "after",
          locateInMenu: "never",
          widget: "dxButton",
          options: {
            icon: "search",
            stylingMode: "outlined",
            onClick: this.toggleSearchBox.bind(this),
          },
        }
      );
    },
    toggleSearchBox() {
      this.visibleSearchCondition = !this.visibleSearchCondition;
    },
    getFontColor(rowType, dataField, rowData, value) {
      var fontColor = "";
      const colorMinus = "FA0000";
      const colorIdx3 = "34B2FA";

      if (rowType == "data") {
        if (rowData.idx == "2") {
          if (
            dataField == "sim1" ||
            dataField == "sim1Deli" ||
            dataField == "sim2" ||
            dataField == "sim2Deli" ||
            dataField == "sim3" ||
            dataField == "sim3Deli"
          ) {
            if (value < 0) {
              fontColor = colorMinus;
            }
          }
        } else if (rowData.idx == "3") {
          if (dataField == "sim1" || dataField == "sim1Deli") {
            fontColor = colorIdx3;
          }
        }
      }

      return fontColor;
    },
    getFontWeight(rowType, dataField, rowData, value) {
      var fontWeight = false;
      if (rowType == "data") {
        if (rowData.idx == "1") {
          fontWeight = true;
        } else if (rowData.idx == "2") {
          if (
            dataField == "sim1" ||
            dataField == "sim1Deli" ||
            dataField == "sim2" ||
            dataField == "sim2Deli" ||
            dataField == "sim3" ||
            dataField == "sim3Deli"
          ) {
            if (value < 0) {
              fontWeight = true;
            }
          } else if (dataField == "srdDate") {
            if (rowData.srdDateChanged == "Y") {
              fontWeight = true;
            }
          }
        } else if (rowData.idx == "3") {
          if (dataField == "sim1" || dataField == "sim1Deli") {
            fontWeight = true;
          }
        }
      }

      return fontWeight;
    },
    getCellColor(rowType, dataField, rowData) {
      // const argbPreFix = 'FF';
      const colorHeader = "E4EEF7";
      const colorSRDOver = "FA5940";
      const colorSRDWw1 = "FFCF57";
      const colorSRDWw2 = "FFFD67";
      const colorSRDWw3 = "A6D068";
      const colorSRDWw4 = "91B3FF";
      const colorSRDOthers = "F5EDFC";

      const colorIdx1 = "D0E1CC";
      const colorIdx1Order = "DFDF96";

      const colorIdx3Color = "EBEBEB";

      var color = "";

      if (rowType == "header") {
        if (dataField == "srdOverQty") {
          color = colorSRDOver;
        } else if (dataField == "srdWw1Qty" || dataField == "deliOverQty") {
          color = colorSRDWw1;
        } else if (dataField == "srdWw2Qty" || dataField == "deliWw2Qty") {
          color = colorSRDWw2;
        } else if (dataField == "srdWw3Qty" || dataField == "deliWw3Qty") {
          color = colorSRDWw3;
        } else if (dataField == "srdWw4Qty" || dataField == "deliWw4Qty") {
          color = colorSRDWw4;
        } else if (
          dataField == "srdOthersQty" ||
          dataField == "deliOthersQty"
        ) {
          color = colorSRDOthers;
        } else {
          color = colorHeader;
        }
      } else if (rowType == "data") {
        if (rowData.idx == "1") {
          if (dataField == "POrder" || dataField == "SOrder") {
            color = colorIdx1Order;
          } else {
            color = colorIdx1;
          }
        } else if (rowData.idx == "2") {
          if (dataField == "srdDate" && this.isSrdDate) {
            let srdWw = rowData.srdWw;
            if (srdWw == "OVER") {
              color = colorSRDOver;
            } else if (srdWw == "WW1") {
              color = colorSRDWw1;
            } else if (srdWw == "WW2") {
              color = colorSRDWw2;
            } else if (srdWw == "WW3") {
              color = colorSRDWw3;
            } else if (srdWw == "WW4") {
              color = colorSRDWw4;
            } else if (srdWw == "OTHERS") {
              color = colorSRDOthers;
            }
          } else if (dataField == "deliDate" && !this.isSrdDate) {
            let deliWw = rowData.deliWw;
            if (deliWw == "OVER") {
              color = colorSRDOver;
            } else if (deliWw == "WW1") {
              color = colorSRDWw1;
            } else if (deliWw == "WW2") {
              color = colorSRDWw2;
            } else if (deliWw == "WW3") {
              color = colorSRDWw3;
            } else if (deliWw == "WW4") {
              color = colorSRDWw4;
            } else if (deliWw == "OTHERS") {
              color = colorSRDOthers;
            }
          }
        } else if (rowData.idx == "3") {
          color = colorIdx3Color;
        }
      }
      return color;
    },
    onCellPrepared(e) {
      var bgColor = this.getCellColor(e.rowType, e.column.dataField, e.data);
      if (bgColor) {
        e.cellElement.bgColor = "#" + bgColor;
      }
      var fontBold = this.getFontWeight(
        e.rowType,
        e.column.dataField,
        e.data,
        e.value
      );
      if (fontBold) {
        e.cellElement.style.fontWeight = "bold";
      }
      var fontColor = this.getFontColor(
        e.rowType,
        e.column.dataField,
        e.data,
        e.value
      );
      if (fontColor) {
        e.cellElement.style.color = "#" + fontColor;
      }
    },
    onCellCkick(e) {
      if (e.columnIndex == 0) {
        if (e.rowType == "header") {
          if (this.viewUnstoredStatus == "+") {
            this.viewUnstoredStatus = "-";
            this.gridData = Query(this.rawData)
              .filter(["idx", "<>", "4"])
              .toArray();
          } else {
            this.viewUnstoredStatus = "+";
            this.gridData = Query(this.rawData)
              .filter(["idx", "<>", "3"])
              .toArray();
          }
          this.gridData.map((item) => {
            if (item.viewUnstoredData) {
              item.viewUnstoredData = this.viewUnstoredStatus;
            }
          });
        } else {
          if (e.data.idx == 1 && e.data.viewUnstoredData) {
            if (e.data.viewUnstoredData == "+") {
              e.data.viewUnstoredData = "-";
            } else {
              e.data.viewUnstoredData = "+";
            }

            var searchCondition = [["idx", "<>", "3"]];

            for (var idx = 0; idx < this.gridData.length; idx++) {
              if (this.gridData[idx].viewUnstoredData == "-") {
                searchCondition.push("or");
                searchCondition.push([
                  ["code", "=", this.gridData[idx].code],
                  "and",
                  ["location", "=", this.gridData[idx].location],
                  "and",
                  ["idx", "=", "3"],
                ]);
              }
            }

            this.gridData = Query(this.rawData)
              .filter(searchCondition)
              .toArray();
          }
        }
      }
    },
    onCellDblCkick(e) {
      if (e.column.dataField == "srdDate") {
        const data = e.data;
        if (data.idx == "2") {
          this.srdDateHistoryList = [];
          this.$refs[this.popupRefName].instance.show();
          this.formDataMSD.dataSource = data.dataSource;
          this.formDataMSD.poNo = data.poNo;
          this.formDataMSD.poSeq = data.poSeq;
          this.formDataMSD.srdDate = data.srdDate;

          dsu
            .getAxiosOBJGet(
              "/api/USDC/selectDdcModifyDataHst",
              this.formDataMSD
            )
            .then((res) => {
              let response = res.data;
              if (response.result == "SUCCESS") {
                this.srdDateHistoryList = response.data;
              } else {
                fn.notifyError(response.msg);
              }
            });
        }
      }
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    // onExporting(e) {
    //   const workbook = new Workbook();
    //   const worksheet = workbook.addWorksheet("Delivery Date Cordination");

    //   exportDataGrid({
    //     component: e.component,
    //     worksheet,
    //     keepColumnWidths: false,
    //     customizeCell: ({ gridCell, excelCell }) => {
    //       const rowType = gridCell.rowType;
    //       const dataField = gridCell.column.dataField;
    //       const dataType = gridCell.column.dataType;
    //       const rowData = gridCell.data;
    //       const value = gridCell.value;

    //       if (rowType == "data" && dataType == "number") {
    //         excelCell.numFmt = "###,###,###,###,###";
    //       }

    //       var bgColor = this.getCellColor(rowType, dataField, rowData);
    //       if (bgColor) {
    //         excelCell.fill = {
    //           type: "pattern",
    //           pattern: "darkVertical",
    //           fgColor: { argb: "FF" + bgColor },
    //           bgColor: { argb: "FF" + bgColor },
    //         };
    //       }

    //       var fontStyle = {};

    //       fontStyle.bold = this.getFontWeight(
    //         rowType,
    //         dataField,
    //         rowData,
    //         value
    //       );

    //       var fontColor = this.getFontColor(rowType, dataField, rowData, value);
    //       if (fontColor) {
    //         fontStyle.color = { argb: "FF" + fontColor };
    //       }

    //       if (fontStyle) {
    //         excelCell.font = fontStyle;
    //       }
    //     },
    //   }).then(() => {
    //     workbook.xlsx.writeBuffer().then((buffer) => {
    //       saveAs(
    //         new Blob([buffer], { type: "application/octet-stream" }),
    //         this.$route.meta.transName + ".xlsx"
    //       );
    //     });
    //   });
    //   e.cancel = true;
    // },
    reSummary(e) {
      e.component.option("disabled", true);
      this.grid.beginCustomLoading("Data Summarizing...");
      dsu.getAxiosOBJGet("/doSummaryDC").then((res) => {
        let response = res.data;
        e.component.option("disabled", false);
        this.grid.endCustomLoading();
        if (response.result == "SUCCESS") {
          fn.notifySuccess(response.msg);
          this.searchData();
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    showSummaryTimees(e) {
      e.component.option("disabled", true);
      dsu.getAxiosOBJGet("/api/USDC/selectDCSummaryTimes").then((res) => {
        let response = res.data;
        e.component.option("disabled", false);
        if (response.result == "SUCCESS") {
          let data = response.data[0];
          let msg =
            "1. 삼성 GSRM PO 정보<br> - " + data.summaryTimeGsrm + "<br><br>";
          // msg +=
          //   "2. Kimball CN SCS PO정보<br> - " +
          //   data.summaryTimeKimballCnPo +
          //   "<br><br>";
          // msg +=
          //   "3. Kimball USA SCS PO정보<br> - " +
          //   data.summaryTimeKimballUsaPo +
          //   "<br><br>";
          msg +=
            "2. LGIT SCS PO정보<br> - " + data.summaryTimeLgitPo + "<br><br>";
          msg +=
            "3. LGD KOR SCS PO정보<br> - " +
            data.summaryTimeLgdKorPo +
            "<br><br>";
          msg +=
            "4. LGD GZ SCS PO정보<br> - " +
            data.summaryTimeLgdGzPo +
            "<br><br>";
          msg +=
            "5. LGD CA SCS PO정보<br> - " +
            data.summaryTimeLgdCaPo +
            "<br><br>";
          msg +=
            "6. LG VS SCS PO 정보<br> - " + data.summaryTimeLgVsPo + "<br><br>";
          msg +=
            "7. Ecount 미판매현황, 미입고현황, 창고별재고<br> - " +
            data.summaryTimeEcount +
            "<br><br>";
          msg +=
            "1~7 에서 수집된 데이터를 최종 Delivery Date Cordination 데이터로 가공<br> -" +
            data.summaryTimeDc +
            "<br><br>";
          msg += "환율(네이버)<br> -" + data.summaryTimeEr + "<br><br>";
          msg +=
            "Ecount 품목 정보<br> -" + data.summaryTimeEcountIm + "<br><br>";
          msg += "Ecount 거래처 정보<br> -" + data.summaryTimeEcountCm;
          fn.alert(msg, "Update Times");
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    saveSrdDate(e) {
      let validationResult =
        this.$refs[this.modifySrdDateRefName].instance.validate();
      if (validationResult.status != "valid") {
        fn.notifyError("SRD Date 입력 후 진행 필요");
        return;
      }

      let params = JSON.parse(JSON.stringify(this.formDataMSD));
      params.srdDate = dsu.getFormatDate(params.srdDate);

      e.component.option("disabled", true);
      dsu.getAxiosOBJPost("/api/USDC/updateDCInfo", params).then((res) => {
        let response = res.data;
        if (response.result == "SUCCESS") {
          fn.notifySuccess(response.msg);
          this.$refs[this.popupRefName].instance.hide();
          this.gridData.map((item) => {
            if (item.poNo == params.poNo && item.poSeq == params.poSeq) {
              item.srdDateChanged = "Y";
              item.srdDate =
                params.srdDate.substr(0, 4) +
                "/" +
                params.srdDate.substr(4, 2) +
                "/" +
                params.srdDate.substr(6, 2);
            }
          });
        } else {
          fn.notifyError(response.msg);
        }

        e.component.option("disabled", false);
      });
    },
    async deleteSrdDate(e) {
      if ((await fn.confirm("정말 삭제 하시겠습니까?")) == true) {
        let validationResult =
          this.$refs[this.modifySrdDateRefName].instance.validate();
        if (validationResult.status != "valid") {
          fn.notifyError("SRD Date 입력 후 진행 필요");
          return;
        }

        let params = JSON.parse(JSON.stringify(this.formDataMSD));
        params.srdDate = dsu.getFormatDate(params.srdDate);

        e.component.option("disabled", true);
        dsu.getAxiosOBJPost("/api/USDC/deleteDCInfo", params).then((res) => {
          let response = res.data;
          if (response.result == "SUCCESS") {
            fn.notifySuccess(response.msg);
            this.$refs[this.popupRefName].instance.hide();
            this.gridData.map((item) => {
              if (item.poNo == params.poNo && item.poSeq == params.poSeq) {
                item.srdDateChanged = "N";
                item.srdDate = item.orgSrdDate;
              }
            });
          } else {
            fn.notifyError(response.msg);
          }
          e.component.option("disabled", false);
        });
      }
    },
  },
};
</script>

<style lang="scss">
#gridUSDC {
  height: calc(100vh - 140px);
}
</style>
