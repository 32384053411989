<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formDataSearch"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />
   
      <DxSimpleItem
        data-field="startDate"
        editor-type="dxDateBox"
        :editor-options="{
          displayFormat: 'yyyy/MM/dd',}"
      >
        <DxLabel text="Start Date" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="endDate"
        editor-type="dxDateBox"
        :editor-options="{
          displayFormat: 'yyyy/MM/dd',}"
      >
        <DxLabel text="End Date" />
        <DxRequiredRule />
      </DxSimpleItem>
      <DxButtonItem
        horizontal-alignment="center"
        vertical-alignment="center"
        :buttonOptions="{
          text: 'Search',
          icon: 'search',
          onClick: searchData,
        }"
      />
    </DxForm>
  </div>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :column-auto-width="true"
      :show-borders="true"
      :show-column-lines="true"
      @cell-prepared="onCellPrepared"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
    >
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multi" />
      <DxExport :enabled="true" />
      <DxSearchPanel :visible="true" :width="500"/>
      
      <DxColumn
        data-field="dt"
        caption="Date"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      /> 
      <DxColumn
        data-field="saSlipNo"
        caption="SA_SLIP_NO"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="doFo"
        caption="DO/FO"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="invoiceNo"
        caption="Invoice NO"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="blNo"
        caption="B/L NO"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saSalesNo"
        caption="SA_SALES_NO"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saCustCd"
        caption="SA_CUST_CD"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saCustNm"
        caption="SA_CUST_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saLocCd"
        caption="SA_LOC_CD"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saLocNm"
        caption="SA_LOC_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saApplication"
        caption="SA_APPLICATION"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saPicNm"
        caption="SA_PIC_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saPersonNm"
        caption="SA_PERSON_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saItemCd"
        caption="SA_ITEM_CD"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="itemName"
        caption="품목명"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="specification"
        caption="규격"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saItemMaterial"
        caption="SA_ITEM_MATERIAL"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saItemKeyword"
        caption="SA_ITEM_KEYWORD"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saQty"
        caption="SA_QTY"
        dataType="number"
        format="###,###,###,###,###"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saCurrency"
        caption="SA_CURRENCY"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saRate"
        caption="SA_RATE"
        dataType="number"
        format="###,###,###,###,###.##"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saUnitPrice"
        caption="SA_UNIT_PRICE"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saAmtFc"
        caption="SA_AMT_FC"
        dataType="number"
        format="###,###,###,###,###"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saAmtWon"
        caption="SA_AMT_WON"
        dataType="number"
        format="###,###,###,###,###"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saVat"
        caption="SA_VAT"
        dataType="number"
        format="###,###,###,###,###"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saCustgrp1Cd"
        caption="SA_CUSTGRP1_CD"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saCustgrp1Nm"
        caption="SA_CUSTGRP1_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saCustgrp2Cd"
        caption="SA_CUSTGRP2_CD"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saCustgrp2Nm"
        caption="SA_CUSTGRP2_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="customerHierarchyGroupCode"
        caption="거래처계층그룹코드"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="customerHierarchyGroupName"
        caption="거래처계층그룹명"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saItemgrp1Cd"
        caption="SA_ITEMGRP1_CD"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saItemgrp1Nm"
        caption="SA_ITEMGRP1_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saItemgrp2Cd"
        caption="SA_ITEMGRP2_CD"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saItemgrp2Nm"
        caption="SA_ITEMGRP2_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saItemgrp3Cd"
        caption="SA_ITEMGRP3_CD"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="saItemgrp3Nm"
        caption="SA_ITEMGRP3_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="itemSize"
        caption="Size"
        dataType="number"
        format="###,###,###,###,###"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="itemHeight"
        caption="높이"
        dataType="number"
        format="###,###,###,###,###"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="transactionType"
        caption="Transaction Type"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="reference"
        caption="Reference"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="remarks"
        caption="Remarks"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="supplierName"
        caption="구매처명"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="custPoNo"
        caption="Cust PO No"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn 
        data-field="lastModifier"
        caption="Last Modifier"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="lastModifiedDate"
        caption="Last Modified Date" 
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
        <DxColumn
        data-field="importedSlip"
        caption="불러온 전표"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="importedSlipDateNo"
        caption="불러온 전표일자-No."
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="orderNoItems"
        caption="주문No."
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="factoryCost"
        caption="공장원가"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="transTime"
        caption="Data 기록 시간"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="userId"
        caption="Data 기록 사용자"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="dbCustgrp2Nm"
        caption="DB_CUSTGRP2_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="dbPersonNm"
        caption="DB_PERSON_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="dbApplication"
        caption="DB_APPLICATION"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="dbItemgrp1Nm"
        caption="DB_ITEMGRP1_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
      <DxColumn
        data-field="dbItemgrp2Nm"
        caption="DB_ITEMGRP2_NM"
        alignment="center"
        :header-filter="{ allowSearch: true }"
      />
    </DxDataGrid>
    <DxPopup
      :ref="popupRefName"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      :width="400"
      :height="300"
      title="Excel Load"
    >
      <form
        id="form"
        :ref="formRefName"
        method="post"
        enctype="multipart/form-data"
      >
        <div class="fileuploader-container">
          <div class="dx-field">
            <DxFileUploader
              :ref="fileUploaderRefName"
              name="file"
              select-button-text="Excel 파일 선택"
              upload-mode="useForm"
              accept=".xlsx"
            />
          </div>
        </div>
        <div class="buttonContainer">
          <DxButton
            text="Load"
            icon="upload"
            width="200"
            @click="uploadExcel"
          />
        </div>
      </form>
    </DxPopup>
  </div>
</template>

<script>
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxButtonItem,
} from "devextreme-vue/form";
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxHeaderFilter,
  DxRequiredRule,
  DxExport,
  DxSearchPanel,
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxButton from "devextreme-vue/button";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

var toDay = new Date();
const startDate = new Date(toDay.getFullYear(), toDay.getMonth() - 1, 1);
const tmpDate = new Date(toDay.getFullYear(), toDay.getMonth() + 2, 1);
const endDate = new Date(tmpDate - 1);

var defaultStartDate = startDate;
var defaultEndDate = endDate;

export default {
  components: {
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxHeaderFilter,
    DxRequiredRule,
    DxButtonItem,
    DxExport,
    DxSearchPanel,
    DxFileUploader,
    DxPopup,
    DxButton,
  },
  data() {
    return {
      searchFormRefName: "searchForm",
      formDataSearch: { startDate: defaultStartDate, endDate: defaultEndDate },
      gridRefName: "grid",
      dataSource: [],
      popupRefName: "popup",
      formRefName: "form",
      fileUploaderRefName: "excelFile",
    };
  },
  computed: {
    grid: function () {
      return this.$refs[this.gridRefName].instance;
    },
    searchForm: function () {
      return this.$refs[this.searchFormRefName].instance;
    },
    popup: function () {
      return this.$refs[this.popupRefName].instance;
    },
    file: function () {
      return this.$refs[this.fileUploaderRefName].instance;
    },
  },
  mounted() {
    this.searchData();
  },
  methods: {
    onToolbarPreparing(e) {
      let toolbarItems = e.toolbarOptions.items;
      toolbarItems.unshift(
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            text: "Load Excel",
            icon: "xlsfile",
            stylingMode: "outlined",
            onClick: this.showExcelUpload.bind(this),
          },
        }
      );

      toolbarItems.forEach(function (item) {
        if (item.name === "searchPanel") {
          item.location = "before";
        }
      });  
    },
    searchData() {
      this.clearGrid();

      var params = {};
      Object.assign(params, this.formDataSearch);
      params.startDate = dsu.getFormatDate(params.startDate);
      params.endDate = dsu.getFormatDate(params.endDate);
      let validationResult = this.searchForm.validate();
      if (validationResult.status != "valid") {
        return;
      }
      this.grid.beginCustomLoading("Data Loading...");

      dsu.getAxiosOBJGet("/api/USRD/selectSalesData", params).then((res) => {
        let response = res.data;
        this.grid.endCustomLoading();

        if (res.data.result == "SUCCESS") {
          this.dataSource = response.data;
        } else {
          fn.notifyError(response.msg);
        }
      });
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    onCellPrepared(e) {
      if (e.rowType == "header") {
        e.cellElement.bgColor = "#E4EEF7";
      } 
    },
    clearGrid() {
      this.dataSource = [];
      this.grid.refresh();
    },
    showExcelUpload() {
      this.file.reset();
      this.popup.show();
    },
    uploadExcel(e) {
      var fileObj = this.file.option("value");
      var params = {};
      if (fileObj.length == 0) {
        fn.notifyError("파일 미 선택");
        return;
      }

      if (fileObj[0].size > (1024 * 1024 * 100)) {
        fn.notifyError("최대용량 초과(100mb)");
        return;
      }

      const form = this.$refs[this.formRefName];
      e.component.option("disabled", true);

      dsu
        .getAxiosOBJPostMultiPart(
          "/api/USRD/uploadExcelSalesData",
          params,
          form
        )
        .then((res) => {
          e.component.option("disabled", false);
          let response = res.data;
          if (response.result == "SUCCESS") {
            this.searchData();
          } else {
            fn.alert(response.msg);
          }
          this.popup.hide();
        });
    },

  },
};
</script>
<style scoped>
#grid {
  height: calc(100vh - 250px);
}
.buttonContainer {
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  text-align: center;
}
</style>
