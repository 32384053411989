<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formData"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />

      <DxSimpleItem
        data-field="location"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.locationInfo,
          valueExpr: 'ID',
          displayExpr: 'Name',
          onValueChanged: onValueChanged,
        }"
      >
        <DxLabel text="Location" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="year"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.yearList,
          valueExpr: 'year',
          displayExpr: 'year',
          onValueChanged: onValueChanged,
        }"
      >
        <DxLabel text="Year" />
        <DxRequiredRule />
      </DxSimpleItem>
    </DxForm>
  </div>
  <div class="content-block dx-card responsive-paddings">
    <tableau-viz
      class="tv"
      v-if="showTableau"
      id="tableauViz"
      src="https://prod-apnortheast-a.online.tableau.com/t/seowoosoft/views/n_/sheet0"
      :token="tableauToken"
      :hide-tabs="true"
      :hide-edit-button="true"
      :hide-edit-in-desktop-button="true"
    >
      <custom-parameter name="location2" :value="location"></custom-parameter>
      <custom-parameter name="year2" :value="year"></custom-parameter>  
    </tableau-viz>

  </div>
</template>

<script>
import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxLabel,
  DxRequiredRule,
} from "devextreme-vue/form";
import { locationFundFlow } from "../../utils/syscodedata";
import axios from "axios";
import store from "../../store";
import dsu from "../../utils/dataSourceUtils";

export default {
  components: {
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
    DxRequiredRule,
  },

  data() {
    return {
      searchFormRefName: "searchForm",
      formData: {},
      locationInfo: locationFundFlow,
      showTableau: false,
      yearList: [],
      tableauToken: "",
      location: "",
      year: "",
    };
  },
  mounted() {
    const menuAuth = JSON.parse(store.getters.getMenuList).find(
      (item) => item.transaction === "UFSE"
    );
    const myLocation = store.getters.getLocation;
    if (menuAuth.f1Auth == "false") {
      this.locationInfo = [{ ID: myLocation, Name: myLocation }];
    }
    this.formData.location = this.locationInfo[0].ID;

    dsu.getAxiosYearList().then((res) => {
      this.yearList = res.data.data;
      this.formData.year = this.yearList[0].year;
    });
  },
  methods: {
    onValueChanged() {
      this.refreshData();
    },
    refreshData() {
      //검색 조건 선택 validation
      var params = {};
      this.showTableau = false;
      Object.assign(params, this.formData);
      if (params.location && params.year) {
        //tableu token 가져오기
        
        let token = store.getters.getToken;
        let paramsTableau = {
          token: token,
          userId: store.getters.getUserId,
          updateExpireTime: "N",
        };
        axios
          .post("/loginTableau", "", {
            headers: { "Content-Type": "application/json; charset=UTF-8" },
            params: paramsTableau,
          })
          .then((res) => {
            this.tableauToken = res.data.msg;
            this.location = params.location;
            this.year = params.year;
            this.showTableau = true;
          });

        //
      }
    },
  },
};
</script>

<style lang="scss">
  .tv{
    height: calc(100vh - 260px);
  }
</style>
