<template>
    <div class="content-block dx-card responsive-paddings">
        <DxForm :ref="searchFormRefName" label-location="left" :form-data="formData">
            <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />
            <DxSimpleItem data-field="factoryCode" editor-type="dxSelectBox" :editor-options="{
                dataSource: this.factoryInfo,
                valueExpr: 'factoryCode',
                      displayExpr: 'factoryName',
                      onValueChanged: onValueChangedFactoryCode,                }">
                <DxLabel text="Factory" />
                <DxRequiredRule />
            </DxSimpleItem>
            <DxSimpleItem data-field="id" editor-type="dxSelectBox" :editor-options="{
                      dataSource: this.checkListInfo,
                      valueExpr: 'id',
                      displayExpr: 'name',
                      onValueChanged: onValueChangedCheckListId,
                    }">
                <DxLabel text="Check List" />
                <DxRequiredRule />
            </DxSimpleItem>
            <DxSimpleItem data-field="cat1" editor-type="dxSelectBox" :editor-options="{
                      dataSource: this.cat1List,
                      valueExpr: 'category',
                      displayExpr: 'category',
                      searchEnabled: true,
                      onValueChanged: onValueChangedCat1,
                    }">
                <DxLabel :text="catNames.cat1Name" />
                <DxRequiredRule />
            </DxSimpleItem>
            <DxSimpleItem data-field="cat2" editor-type="dxSelectBox" :editor-options="{
                      dataSource: this.cat2List,
                      valueExpr: 'category',
                      displayExpr: 'category',
                      searchEnabled: true,
                      showClearButton: true,
                      onValueChanged: refreshData,
                    }">
                <DxLabel :text="catNames.cat2Name" />
            </DxSimpleItem>
        </DxForm>
    </div>
    <div class="content-block dx-card responsive-paddings">
        <DxDataGrid id="grid" :ref="gridRefName" :data-source="dataSource" :column-auto-width="true" :show-borders="true" :show-column-lines="true" :focused-row-enabled="true" key-expr="dummyKey" @exporting="onExporting" @row-inserted="onRowInserted" @row-removed="onRowRemoved"
            @row-inserting="onRowInserting" @editorPreparing="onEditorPreparing($event)" @toolbar-preparing="onToolbarPreparing($event)">
            <DxSearchPanel :visible="true" />
            <DxHeaderFilter :visible="true" />
            <DxScrolling mode="infinite" />
            <DxSorting mode="multi" />
            <DxExport :enabled="true" />
            <DxEditing mode="popup" :allow-updating="true" :allow-adding="true" :allow-deleting="true" />
            <DxRowDragging :allow-reordering="true" :on-reorder="onReorder" :show-drag-icons="true" />
            <DxColumn type="buttons">
                <DxButton name="edit" />
            </DxColumn>
            <DxColumn data-field="num" caption="Sequence" :allowEditing="false" :header-filter="{ allowSearch: true }">
            </DxColumn>
            <DxColumn data-field="type" caption="Type" :header-filter="{ allowSearch: true }" cell-template="text-area-template">
                <DxRequiredRule />
            </DxColumn>
            <DxColumn data-field="checkPoint" caption="Check Point" :header-filter="{ allowSearch: true }" cell-template="text-area-template">
                <DxRequiredRule />
            </DxColumn>
            <DxColumn data-field="item" caption="Check Info" :header-filter="{ allowSearch: true }" cell-template="text-area-template">
                <DxRequiredRule />
            </DxColumn>
            <DxColumn data-field="importantPoint" caption=" Important Point" :header-filter="{ allowSearch: true }" cell-template="text-area-template">
            </DxColumn>
            <DxColumn data-field="score" caption="Points" data-type="number" :editor-options="{ min: 1, max: 99 }">
                <DxRequiredRule />
            </DxColumn>
            <DxColumn data-field="remark" caption="Remark" :header-filter="{ allowSearch: true }" cell-template="text-area-template">
            </DxColumn>
            <DxColumn data-field="failScore" caption="Fail Criterion" data-type="number" :editor-options="{ min: 1, max: 99 }">
            </DxColumn>
            <DxColumn data-field="style" caption="Style" :editorOptions="{ showClearButton: true }">
                <DxLookup :data-source="[
                    { id: 'GREEN', Name: 'GREEN' },
                    { id: 'RED', Name: 'RED' },
                ]" value-expr="id" display-expr="Name" />
            </DxColumn>
            <template #text-area-template="{ data }">
                    <pre>{{ data.value }}</pre>
            </template>
      <DxTextArea />
    </DxDataGrid>

    <DxPopup
      :ref="popupRefName"
      :drag-enabled="false"
      :close-on-outside-click="true"
      :show-title="true"
      :width="400"
      :height="300"
      title="Excel Load"
    >
      <form
        id="form"
        :ref="formRefName"
        method="post"
        enctype="multipart/form-data"
      >
        <div class="fileuploader-container">
          <div class="dx-field">
            <DxFileUploader
              :ref="fileUploaderRefName"
              name="file"
              select-button-text="Excel 파일 선택"
              upload-mode="useForm"
              accept=".xlsx"
            />
          </div>
        </div>
        <div class="buttonContainer">
          <DxButton
            text="Load"
            icon="upload"
            width="200"
            @click="uploadExcel"
          />
        </div>
      </form>
    </DxPopup>
  </div>
</template>

<script>
import {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxEditing,
    DxSearchPanel,
    DxRequiredRule,
    DxRowDragging,
    DxLookup,
} from "devextreme-vue/data-grid";
import {
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxLabel,
} from "devextreme-vue/form";
import { DxTextArea } from "devextreme-vue/text-area";
import DxPopup from "devextreme-vue/popup";
import { DxFileUploader } from "devextreme-vue/file-uploader";
import DxButton from "devextreme-vue/button";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
    components: {
        DxDataGrid,
        DxColumn,
        DxScrolling,
        DxSorting,
        DxExport,
        DxHeaderFilter,
        DxEditing,
        DxSearchPanel,
        DxRequiredRule,
        DxTextArea,
        DxRowDragging,
        DxForm,
        DxColCountByScreen,
        DxSimpleItem,
        DxLabel,
        DxLookup,
        DxFileUploader,
        DxPopup,
        DxButton,
    },
    data() {
        return {
            searchFormRefName: "searchForm",
            formData: {},
            gridRefName: "grid",
            dataSource: [],
            factoryInfo: [],
            checkListId: "",
            checkListInfo: [],
            catNames: {},
            cat1List: [],
            cat2List: [],
            popupRefName: "popup",
            formRefName: "form",
            fileUploaderRefName: "excelFile",
        };
    },
    computed: {
        grid: function() {
            return this.$refs[this.gridRefName].instance;
        },
        popup: function() {
            return this.$refs[this.popupRefName].instance;
        },
        file: function() {
            return this.$refs[this.fileUploaderRefName].instance;
        },
    },
    mounted() {
        dsu.getAxiosFactoryInfo().then((res) => {
            this.factoryInfo = res.data.data;
        });
    },
    methods: {
        onExporting(e) {
            e.fileName = this.$route.meta.transName;
        },
        onEditorPreparing: function(e) {
            if (e.dataField == "item") {
                e.editorName = "dxTextArea";
                e.editorOptions.height = 200;
                e.editorOptions.maxLength = 200;
            } else if (e.dataField == "importantPoint" || e.dataField == "remark") {
                e.editorName = "dxTextArea";
                e.editorOptions.height = 150;
                e.editorOptions.maxLength = 100;
            } else if (e.dataField == "type" || e.dataField == "checkPoint") {
                e.editorOptions.inputAttr = { style: "text-transform: uppercase" };
                e.editorName = "dxTextArea";
                e.editorOptions.height = 100;
                e.editorOptions.maxLength = 20;
            }
        },
        onToolbarPreparing(e) {
            e.toolbarOptions.items.unshift({
                location: "after",
                widget: "dxButton",
                locateInMenu: "auto",
                options: {
                    text: "Load Excel",
                    icon: "xlsfile",
                    stylingMode: "outlined",
                    onClick: this.showExcelUpload.bind(this),
                },
            }, {
                location: "after",
                widget: "dxButton",
                locateInMenu: "auto",
                options: {
                    text: "Save",
                    icon: "save",
                    stylingMode: "outlined",
                    onClick: this.save.bind(this),
                },
            }, {
                location: "after",
                widget: "dxButton",
                locateInMenu: "auto",
                options: {
                    text: "Delete",
                    icon: "remove",
                    stylingMode: "outlined",
                    onClick: this.delete.bind(this),
                },
            });
        },
        refreshData() {
            this.dataSource = [];
            var params = {};
            Object.assign(params, this.formData);

            if (params.cat1) {
                if (!params.cat2) {
                    params.cat2 = "NONE";
                }
                dsu
                    .getAxiosOBJGet("/api/ACII/selectCheckListItemInfo", params)
                    .then((res) => {
                        this.dataSource = res.data.data;
                    });
            }
        },
        makeNewNums() {
            for (let i = 0; i < this.dataSource.length; i++) {
                this.dataSource[i].num = i + 1;
            }
        },
        onValueChangedFactoryCode(e) {
            this.formData.id = "";
            var params = { factoryCode: e.value };
            dsu.getAxiosCheckListInfo(params).then((res) => {
                this.checkListInfo = res.data.data;
            });
        },
        onValueChangedCheckListId(e) {
            let params = { id: e.value };

            this.formData.cat1 = "";

            dsu.getAxiosCheckListInfo(params).then((res) => {
                this.catNames = res.data.data[0];
            });

            dsu.getAxiosCheckListCatInfo(params).then((res) => {
                this.cat1List = res.data.data;
            });
        },
        onValueChangedCat1() {
            this.formData.cat2 = "";

            let params = { id: this.checkListId, parentCategory: this.formData.cat1 };

            dsu.getAxiosCheckListCat2Info(params).then((res) => {
                this.cat2List = res.data.data;
            });

            this.refreshData();
        },
        onRowRemoved() {
            this.makeNewNums();
        },
        onReorder(e) {
            const visibleRows = e.component.getVisibleRows();
            const toIndex = this.dataSource.findIndex(
                (item) => item.num === visibleRows[e.toIndex].data.num
            );
            const fromIndex = this.dataSource.findIndex(
                (item) => item.num === e.itemData.num
            );
            const newDatas = [...this.dataSource];

            newDatas.splice(fromIndex, 1);
            newDatas.splice(toIndex, 0, e.itemData);

            this.dataSource = newDatas;
            this.makeNewNums();
        },
        onRowInserted() {
            const focusedRowIndex = this.grid.option("focusedRowIndex");
            const lastIndex = this.dataSource.length - 1;

            if (focusedRowIndex > -1) {
                const newDatas = [...this.dataSource];
                newDatas.splice(lastIndex, 1);
                newDatas.splice(focusedRowIndex + 1, 0, this.dataSource[lastIndex]);
                this.dataSource = newDatas;
            }
            this.makeNewNums();
        },
        save(e) {
            const searchForm = this.$refs[this.searchFormRefName].instance;

            let validationResult = searchForm.validate();
            if (validationResult.status != "valid") {
                fn.notifyError("Factory, Check List, Category 정보 선택 후 진행 필요");
                return;
            }

            if (this.dataSource.length < 1) {
                fn.notifyError("Check Item 입력 후 진행 필요");
                return;
            }

            var params = {};
            Object.assign(params, this.formData);
            if (!params.cat2) {
                params.cat2 = "NONE";
            }

            params.checkListItemInfo = JSON.stringify(this.dataSource);
            e.component.option("disabled", true);

            dsu
                .getAxiosOBJPost("/api/ACII/updateCheckListItemInfo", params)
                .then((res) => {
                    let response = res.data;
                    if (response.result == "SUCCESS") {
                        fn.notifySuccess(response.msg);
                    } else {
                        fn.notifyError(response.msg);
                    }
                    e.component.option("disabled", false);
                });
        },
        async delete(e) {
            const searchForm = this.$refs[this.searchFormRefName].instance;

            let validationResult = searchForm.validate();
            if (validationResult.status != "valid") {
                fn.notifyError("Factory, Check List, Category 정보 선택 후 진행 필요");
                return;
            }

            if ((await fn.confirm("정말 삭제 하시겠습니까?")) == false) {
                return;
            }

            var params = {};
            Object.assign(params, this.formData);
            if (!params.cat2) {
                params.cat2 = "NONE";
            }

            e.component.option("disabled", true);
            dsu
                .getAxiosOBJPost("/api/ACII/deleteCheckListItemInfo", params)
                .then((res) => {
                    let response = res.data;
                    if (response.result == "SUCCESS") {
                        fn.notifySuccess(response.msg);
                        this.dataSource = [];
                    } else {
                        fn.notifyError(response.msg);
                    }

                    e.component.option("disabled", false);
                });
        },
        onRowInserting(e) {
            e.data.type = e.data.type.toUpperCase();
            e.data.checkPoint = e.data.checkPoint.toUpperCase();
        },
        showExcelUpload() {
            this.file.reset();

            const searchForm = this.$refs[this.searchFormRefName].instance;

            let validationResult = searchForm.validate();
            if (validationResult.status != "valid") {
                fn.notifyError("Check List, Category 정보 선택 후 진행 필요");
                return;
            }

            this.popup.show();
        },
        uploadExcel(e) {
            var fileObj = this.file.option("value");

            if (fileObj.length == 0) {
                fn.notifyError("파일 미 선택");
                return;
            }

            if (fileObj[0].size > 104857600) {
                fn.notifyError("최대용량 초과(100mb)");
                return;
            }

            var params = {};
            Object.assign(params, this.formData);
            if (!params.cat2) {
                params.cat2 = "NONE";
            }

            const form = this.$refs[this.formRefName];
            e.component.option("disabled", true);

            dsu
                .getAxiosOBJPostMultiPart(
                    "/api/ACII/uploadExcelCheckListItemInfo",
                    params,
                    form
                )
                .then((res) => {
                    e.component.option("disabled", false);
                    let response = res.data;
                    if (response.result == "SUCCESS") {
                        this.dataSource = response.data;
                    } else {
                        fn.alert(response.msg);
                    }
                    this.popup.hide();
                });
        },
    },
};
</script>

<style scoped>
#grid {
    max-height: calc(100vh - 350px);
}

.fileuploader-container {
    border: 1px solid #d3d3d3;
    text-align: center;
    height: 135px;
}

.buttonContainer {
    padding-top: 15px;
    padding-left: 15px;
    padding-right: 15px;
    text-align: center;
}
</style>
