<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
      @focused-row-changed="selectedRowChanged($event)"
      @editorPreparing="onEditorPreparing($event)"
      @row-inserting="onRowInserting"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multiple" />
      <DxExport :enabled="true" />
      <DxEditing
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        mode="popup"
      />

      <DxColumn type="buttons">
        <DxButton name="edit" />
      </DxColumn>

      <DxColumn
        data-field="id"
        caption="ID"
        :header-filter="{ allowSearch: true }"
      >
        <DxRequiredRule />
        <DxPatternRule
          pattern="^[a-zA-Z0-9-]{1,20}$"
          message="영문 숫자 - 로만 구성, 20자 까지 등록 가능"
        />
      </DxColumn>

      <DxColumn
        data-field="userName"
        caption="Name"
        :header-filter="{ allowSearch: true }"
      >
        <DxRequiredRule />
        <DxPatternRule pattern="^.{2,20}$" message="2~20자 까지 등록 가능" />
      </DxColumn>

      <DxColumn data-field="menuAuth" caption="Authority">
        <DxRequiredRule />
        <DxLookup
          :data-source="menuAuth"
          value-expr="groupId"
          display-expr="groupName"
        />
      </DxColumn>

      <DxColumn data-field="location" caption="LOCATION">
        <DxRequiredRule />
        <DxLookup :data-source="location" value-expr="ID" display-expr="Name" />
      </DxColumn>

      <DxMasterDetail :enabled="true" template="master-detail" />
      <template #master-detail="{ data }">
        <History
          :master-detail-data="data"
          :url="`/api/AUSR/selectHistory`"
          :keyName="operation"
          :columns="historyColumns"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxExport,
  DxHeaderFilter,
  DxEditing,
  DxSearchPanel,
  DxLookup,
  DxRequiredRule,
  DxPatternRule,
  DxMasterDetail,
} from "devextreme-vue/data-grid";
import History from "../_common/SIMPLE_HISTORY";
import { userAuth, locationFundFlow } from "../../utils/syscodedata";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxEditing,
    DxSearchPanel,
    DxLookup,
    DxRequiredRule,
    DxPatternRule,
    DxMasterDetail,
    History,
  },
  data() {
    return {
      gridRefName: "grid",
      dataSource: dsu.getComDSGrid("/api/AUSR", "id", "", "ID"),
      userAuth: userAuth,
      location: locationFundFlow,
      selectedRowData: null,
      historyColumns: [
        { dataField: "transTime", caption: "Update Time" },
        { dataField: "userName", caption: "Name" },
        { dataField: "menuAuth", caption: "Menu Auth" },
        { dataField: "location", caption: "Location" },
        { dataField: "password", caption: "Password" },
        { dataField: "userId", caption: "Update User" },
        { dataField: "dml", caption: "Action" },
      ],
      menuAuth: [],
    };
  },
  mounted() {
    dsu.getAxiosOBJGet("/api/AMGR/selectAuthGroupInfo").then((res) => {
      if (res.data.result == "SUCCESS") {
        this.menuAuth = res.data.data;
      } else {
        fn.notifyError(res.data.msg);
      }
    });
  },
  methods: {
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    onEditorPreparing: function (e) {
      if (e.dataField == "id") {
        e.editorOptions.inputAttr = { style: "text-transform: uppercase" };
      }
    },
    onRowInserting(e) {
      e.data.id = e.data.id.toUpperCase();
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift({
        location: "after",
        widget: "dxButton",
        locateInMenu: "auto",
        options: {
          icon: "refresh",
          text: "Reset Password",
          stylingMode: "outlined",
          onClick: this.resetPassword.bind(),
        },
      });
    },
    selectedRowChanged(e) {
      this.selectedRowData = e.row.data;
    },
    resetPassword() {
      if (!this.selectedRowData) {
        fn.notifyError("Need to proceed after user selection");
        return;
      }

      var params = { id: this.selectedRowData.id, password: "coilmaster" };

      dsu.getAxiosOBJGet("/api/AUSR/update", params).then((res) => {
        let rtnMsg = res.data.msg;
        if (res.data.result == "SUCCESS") {
          fn.notifySuccess("비밀번호 초기화 완료");
          this.$refs[this.gridRefName].instance.refresh();
        } else {
          fn.notifyError(rtnMsg);
        }
      });
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 210px);
}
</style>
