<template>
  <div class="content-block dx-card responsive-paddings">
    <DxForm
      :ref="searchFormRefName"
      label-location="left"
      :form-data="formData"
    >
      <DxColCountByScreen :xs="1" :sm="2" :md="3" :lg="4" />

      <DxSimpleItem
        data-field="factoryCode"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.factoryInfo,
          valueExpr: 'factoryCode',
          displayExpr: 'factoryName',
          onValueChanged: onValueChangedFactoryCode,
        }"
      >
        <DxLabel text="Factory" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="id"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.checkListInfo,
          valueExpr: 'id',
          displayExpr: 'name',
          onValueChanged: onValueChangedCheckListId,
        }"
      >
        <DxLabel text="Check List" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="cat1"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.cat1List,
          valueExpr: 'category',
          displayExpr: 'category',
          showClearButton: true,
          searchEnabled: true,
          onValueChanged: onValueChangedCat1,
        }"
      >
        <DxLabel :text="catNames.cat1Name" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="cat2"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: this.cat2List,
          valueExpr: 'category',
          displayExpr: 'category',
          showClearButton: true,
          searchEnabled: true,
          onValueChanged: onValueChangedCat2,
        }"
      >
        <DxLabel :text="catNames.cat2Name" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="cat3"
        editor-type="dxTextBox"
        :editor-options="{ maxLength: '30' }"
      >
        <DxLabel :text="catNames.cat3Name" />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="startDate"
        editor-type="dxDateBox"
        :editor-options="{ displayFormat: 'yyyy/MM/dd' }"
      >
        <DxLabel text="Start Date" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="endDate"
        editor-type="dxDateBox"
        :editor-options="{ displayFormat: 'yyyy/MM/dd' }"
      >
        <DxLabel text="End Date" />
        <DxRequiredRule />
      </DxSimpleItem>

      <DxSimpleItem
        data-field="status"
        editor-type="dxSelectBox"
        :editor-options="{
          dataSource: [{ name: 'COMPLETE' }, { name: 'INCOMPLETE' }],
          valueExpr: 'name',
          displayExpr: 'name',
          showClearButton: true,
        }"
      >
        <DxLabel text="Status" />
      </DxSimpleItem>

      <DxButtonItem
        horizontal-alignment="center"
        vertical-alignment="center"
        :buttonOptions="{
          text: 'Search',
          icon: 'search',
          onClick: searchCheckList,
        }"
      />
    </DxForm>
  </div>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :dataSource="checkListData"
      :show-borders="true"
      :show-column-lines="true"
      :columnAutoWidth="true"
      :key-expr="['regDate', 'cat1', 'cat2', 'cat3']"
      :allowColumnResizing="true"
      @rowDblClick="onDblClick"
      @cell-prepared="onCellPrepared"
      @exporting="onExporting"
      @toolbar-preparing="onToolbarPreparing($event)"
      @selection-changed="onSelectionChanged"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multiple" />
      <DxExport :enabled="true" />
      <DxSelection show-check-boxes-mode="always" mode="multiple" />
      <DxExport :enabled="true" />

      <DxColumn
        data-field="transTime"
        caption="Reg. Date"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="cat1"
        :caption="catNames.cat1Name"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="cat2"
        :caption="catNames.cat2Name"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn
        data-field="cat3"
        :caption="catNames.cat3Name"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>

      <DxColumn caption="The Person in Charge">
        <DxColumn
          data-field="scoreUser"
          caption="Score"
          :header-filter="{ allowSearch: true }"
        >
        </DxColumn>

        <DxColumn
          data-field="inputStatusUser"
          caption="Input Status"
          :header-filter="{ allowSearch: true }"
        >
        </DxColumn>

        <DxColumn
          data-field="resultUser"
          caption="Result"
          :header-filter="{ allowSearch: true }"
        >
        </DxColumn>

        <DxColumn
          data-field="userName"
          caption="Name"
          :header-filter="{ allowSearch: true }"
        >
        </DxColumn>
      </DxColumn>

      <DxColumn caption="Manager">
        <DxColumn
          data-field="scoreAdmin"
          caption="Score"
          :header-filter="{ allowSearch: true }"
        >
        </DxColumn>

        <DxColumn
          data-field="inputStatusAdmin"
          caption="Input Status"
          :header-filter="{ allowSearch: true }"
        >
        </DxColumn>

        <DxColumn
          data-field="resultAdmin"
          caption="Result"
          :header-filter="{ allowSearch: true }"
        >
        </DxColumn>

        <DxColumn
          data-field="adminName"
          caption="Name"
          :header-filter="{ allowSearch: true }"
        >
        </DxColumn>
      </DxColumn>
      <DxColumn
        data-field="lastUpdateDate"
        caption="Last Update"
        :header-filter="{ allowSearch: true }"
      >
      </DxColumn>
    </DxDataGrid>

    <DxPopup
      :ref="popupScordRefName"
      height="auto"
      width="90%"
      :drag-enabled="false"
      :hideOnOutsideClick="true"
      :show-title="false"
    >
      <CHECK_LIST_DATA
        :selectedCheckListData="selectedCheckListData"
        :isEditingMode="false"
      />
    </DxPopup>
  </div>
</template>

<script>
var endDate = new Date();
var startDate = new Date();
startDate.setDate(endDate.getDate() - 7);
var defaultStartDate = startDate;
var defaultEndDate = endDate;

import {
  DxForm,
  DxColCountByScreen,
  DxSimpleItem,
  DxButtonItem,
  DxLabel,
  DxRequiredRule,
} from "devextreme-vue/form";
import {
  DxDataGrid,
  DxScrolling,
  DxColumn,
  DxSorting,
  DxSearchPanel,
  DxHeaderFilter,
  DxExport,
  DxSelection,
} from "devextreme-vue/data-grid";
import DxPopup from "devextreme-vue/popup";
import CHECK_LIST_DATA from "../../views/_common/CHECK_LIST_DATA";
import dsu from "../../utils/dataSourceUtils";
import fn from "../../utils/function";

export default {
  components: {
    DxForm,
    DxColCountByScreen,
    DxSimpleItem,
    DxButtonItem,
    DxLabel,
    DxRequiredRule,
    DxDataGrid,
    DxScrolling,
    DxColumn,
    DxSorting,
    DxSearchPanel,
    DxHeaderFilter,
    DxExport,
    CHECK_LIST_DATA,
    DxPopup,
    DxSelection,
  },
  data() {
    return {
      searchFormRefName: "searchForm",
      gridRefName: "grid",
      formData: { startDate: defaultStartDate, endDate: defaultEndDate },
      factoryInfo: [],
      checkListInfo: [],
      catNames: {},
      cat1List: [],
      cat2List: [],
      selectedCheckListData: {},
      checkListData: [],
      popupScordRefName: "popup",
      selectedRowData: [],
    };
  },
  mounted() {
    dsu.getAxiosFactoryInfo().then((res) => {
      this.factoryInfo = res.data.data;
    });
  },
  methods: {
    onValueChangedFactoryCode(e) {
      this.formData.id = "";
      var params = { factoryCode: e.value };
      dsu.getAxiosCheckListInfo(params).then((res) => {
        this.checkListInfo = res.data.data;
      });
    },

    onValueChangedCheckListId(e) {
      this.formData.cat1 = "";
      this.cat1List = [];

      if (e.value) {
        let params = { id: e.value };
        dsu.getAxiosCheckListInfo(params).then((res) => {
          this.catNames = res.data.data[0];
        });

        dsu.getAxiosCheckListCatInfo(params).then((res) => {
          this.cat1List = res.data.data;
        });
      }
    },
    onValueChangedCat1(e) {
      this.formData.cat2 = "";
      this.cat2List = [];

      if (e.value) {
        let params = { id: this.formData.id, parentCategory: e.value };

        dsu.getAxiosCheckListCat2Info(params).then((res) => {
          this.cat2List = res.data.data;
          if (this.$route.query.cat2) {
            this.formData.cat2 = this.$route.query.cat2;
          }
        });
      }
    },
    onValueChangedCat2() {
      if (this.$route.query.cat3) {
        this.formData.cat3 = this.$route.query.cat3;
      }
    },
    searchCheckList() {
      const searchForm = this.$refs[this.searchFormRefName].instance;

      let validationResult = searchForm.validate();
      if (validationResult.status != "valid") {
        return;
      }

      Object.assign(this.selectedCheckListData, this.formData);
      this.selectedCheckListData.startDate = dsu.getFormatDate(
        this.selectedCheckListData.startDate
      );
      this.selectedCheckListData.endDate = dsu.getFormatDate(
        this.selectedCheckListData.endDate
      );
      console.log(JSON.stringify(this.selectedCheckListData));
      dsu
        .getAxiosOBJGet(
          "/api/UCLS/selectCheckListData",
          this.selectedCheckListData
        )
        .then((res) => {
          let response = res.data;
          if (response.result == "SUCCESS") {
            this.checkListData = response.data;
          } else {
            fn.notifyError(response.msg);
          }
        });
    },
    onDblClick(e) {
      Object.assign(this.selectedCheckListData, e.data);
      this.$refs[this.popupScordRefName].instance.show();
    },
    onCellPrepared(e) {
      if (e.rowType == "data") {
        var dataField = e.column.dataField;
        var data = e.data[dataField];

        if (dataField) {
          if (dataField == "resultUser" || dataField == "resultAdmin") {
            if (data == "NG" || data == "ITEM_NG") {
              e.cellElement.bgColor = "#F6C7B3";
            } else if (data == "REVIEW") {
              e.cellElement.bgColor = "#F6EAC2";
            } else if (data == "PASS") {
              e.cellElement.bgColor = "#CCE2CB";
            }
          }
        }
      }
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    onToolbarPreparing(e) {
      e.toolbarOptions.items.unshift(
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            text: "Modify",
            icon: "edit",
            stylingMode: "outlined",
            onClick: this.moveEdit.bind(this),
          },
        },
        {
          location: "after",
          widget: "dxButton",
          locateInMenu: "auto",
          options: {
            text: "Delete",
            icon: "remove",
            stylingMode: "outlined",
            onClick: this.delete.bind(this),
          },
        }
      );
    },
    onSelectionChanged(e) {
      this.selectedRowData = e.selectedRowsData;
    },
    moveEdit() {
      if (this.selectedRowData.length < 1) {
        fn.notifyError("수정 대상 선택 후 진행 필요");
        return;
      } else if (this.selectedRowData.length > 1) {
        fn.notifyError("하나의 수정 대상만 선택 후 진행 필요");
        return;
      }

      this.$router.push({
        name: "uclr",
        query: {
          factoryCode: this.formData.factoryCode,
          id: this.selectedRowData[0].id,
          cat1: this.selectedRowData[0].cat1,
          cat2: this.selectedRowData[0].cat2,
          cat3: this.selectedRowData[0].cat3,
          regDate: this.selectedRowData[0].regDate,
        },
      });
    },
    async delete(e) {
      if (this.selectedRowData.length < 1) {
        fn.notifyError("Delete 대상 선택 후 진행 필요");
        return;
      }

      if ((await fn.confirm("정말 삭제 하시겠습니까?")) == false) {
        return;
      }

      var params = {};

      params.checkListData = JSON.stringify(this.selectedRowData);

      e.component.option("disabled", true);

      dsu
        .getAxiosOBJPost("/api/UCLR/deleteCheckListItemDataMulti", params)
        .then((res) => {
          let response = res.data;
          if (response.result == "SUCCESS") {
            fn.notifySuccess(response.msg);
            this.searchCheckList();
          } else {
            fn.notifyError(response.msg);
          }

          e.component.option("disabled", false);
        });
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 430px);
}
</style>
