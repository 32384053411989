<template>
   <div class="content-block dx-card responsive-paddings">
    <DxToolbar>
      <DxItem
        :options="dateBoxOptionsStart"
        location="center"
        locate-in-menu="auto"
        widget="dxDateBox"
      />
      <DxItem
        :options="dateBoxOptionsEnd"
        location="center"
        locate-in-menu="auto"
        widget="dxDateBox"
      />
      <DxItem
        :options="buttonOptionsSearch"
        location="center"
        locate-in-menu="never"
        widget="dxButton"
      />
    </DxToolbar>
  </div>

  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      @cell-prepared="onCellPrepared">
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="virtual" />
      <DxSorting mode="multiple" />
      <DxExport :enabled="true" />
      <DxColumn data-field="custCode" caption="Cust Code" />
      <DxColumn data-field="custName" caption="Cust Name" />
      <DxColumn data-field="itemGroup2" caption="Item Group 2" />
      <DxColumn data-field="itemCode" caption="Item Code" />
      <DxColumn data-field="itemName" caption="Item Name" />
      <DxColumn data-field="saAmtWon" caption="Sales AMT(KRW)" data-type="number" format="###,###,###,###,###"/>
      <DxColumn data-field="saQty" caption="Sales Qty" data-type="number" format="###,###,###,###,###"/>
      <DxColumn data-field="factoryCost" caption="Factory Cost(KRW)" data-type="number"/>
      <DxColumn data-field="factorySalesPriceAvg" caption="Factory Sales Price AVG(KRW)" data-type="number"/>
      <DxColumn data-field="factoryProfit" caption="Factory Profit(KRW)" data-type="number"/>
      <DxColumn data-field="salesPrice" caption="Sales Price(KRW)" data-type="number"/>
      <DxColumn data-field="salesProfit" caption="Sales Profit(KRW)" data-type="number"/>
      <DxMasterDetail
        :enabled="true"
        template="master-detail"/>
        
        <template #master-detail="{ data }">
          <History :master-detail-data="data"
                  :url= "`/api/MFSP/selectFactorySalesPrice`"
                  :keyName="['locNm', 'custNm']"
                  :key1="data.data.itemCode"
                  :columns="historyColumns"/>
        </template>    

    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxExport,
  DxHeaderFilter,
  DxSearchPanel,
  DxMasterDetail
} from "devextreme-vue/data-grid";
import DxToolbar, { DxItem } from "devextreme-vue/toolbar";
import History from "../_common/SIMPLE_HISTORY";
import dsu from "../../utils/dataSourceUtils";
var endDate = new Date();
var startDate = new Date();
startDate.setFullYear(endDate.getFullYear() - 1);
var defaultStartDate = startDate;
var defaultEndDate = endDate;

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxSearchPanel,
    DxToolbar,
    DxItem,
    History,
    DxMasterDetail
  },
  data() {
    return {
      gridRefName: "grid",
      dataSource: [],
      searchCondition: { startDate: defaultStartDate, endDate: defaultEndDate },
      dateBoxOptionsStart: {
        width: 190,
        value: defaultStartDate,
        label: "Start",
        displayFormat: "yyyy/MM/dd",
        onValueChanged: (args) => {
          this.searchCondition.startDate = args.value;
        },
      },
      dateBoxOptionsEnd: {
        width: 190,
        value: defaultEndDate,
        label: "End",
        displayFormat: "yyyy/MM/dd",
        onValueChanged: (args) => {
          this.searchCondition.endDate = args.value;
        },
      },
      buttonOptionsSearch: {
        icon: "search",
        text: "Search",
        stylingMode: "outlined",
        onClick: this.search.bind(),
      },
      historyColumns: [{dataField: "locNm", caption : "Location"},
                {dataField: "custNm", caption : "Cust Name"},
                {dataField: "salesPrice", caption: "Factory Sales Price", dataType: "number"},]
      };
  },
  mounted() {
    this.refreshData();
  },
  computed: {
    grid: function () { return this.$refs[this.gridRefName].instance; }
  },
  methods: {
    onCellPrepared(e) {
      if (e.rowType == "header") {
        e.cellElement.bgColor = "#E4EEF7";
      } else if (e.rowType == "data") {
        if (e.column.dataField == "factoryProfit" || e.column.dataField == "salesProfit") {
          e.cellElement.bgColor ="#F5EDFC"
        }
      }
    },
    search() {
      var params = JSON.parse(JSON.stringify(this.searchCondition));
      params.startDate = dsu.getFormatDate(this.searchCondition.startDate);
      params.endDate = dsu.getFormatDate(this.searchCondition.endDate);
      this.grid.beginCustomLoading("Data Loading...");
      dsu.getAxiosOBJGet("/api/MASP/selectProfitInfo", params).then((res) => {
          this.grid.endCustomLoading();
          this.dataSource = res.data.data;
      });
    },
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
    refreshData() {
      
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 330px);
}
</style>
