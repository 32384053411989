<template>
  <div class="content-block dx-card responsive-paddings">
    <DxDataGrid
      id="grid"
      :ref="gridRefName"
      :data-source="dataSource"
      :columnAutoWidth="true"
      :show-borders="true"
      :show-column-lines="true"
      :focused-row-enabled="true"
    >
      <DxSearchPanel :visible="true" />
      <DxHeaderFilter :visible="true" />
      <DxScrolling mode="infinite" />
      <DxSorting mode="multiple" />
      <DxExport :enabled="true" />
      
      <DxEditing
        :allow-updating="true"
        :allow-adding="true"
        :allow-deleting="true"
        mode="popup"
      />

      <DxColumn type="buttons">
        <DxButton name="edit" />
      </DxColumn>

      <DxColumn
        data-field="dt"
        caption="Date"
        dataType="date"
        format="yyyy/MM/dd"
        :header-filter="{ allowSearch: true }"
        :editor-options="{
          displayFormat: 'yyyy/MM/dd',
          dateSerializationFormat: 'yyyy/MM/dd',
        }"
      >
        <DxRequiredRule />
      </DxColumn>

      <DxColumn
        data-field="amountEur"
        caption="EUR"
        dataType="number"
        format="###,###,###,##0.00"
        :header-filter="{ allowSearch: true }"
        :editor-options="{
          format: '###,###,###,##0.00',
        }"
      >
        <DxRequiredRule />
      </DxColumn>

      <DxColumn
        data-field="amountUsd"
        caption="USD"
        dataType="number"
        format="###,###,###,##0.00"
        :header-filter="{ allowSearch: true }"
        :editor-options="{
          format: '###,###,###,##0.00',
        }"
      >
        <DxRequiredRule />
      </DxColumn>

      <DxColumn
        data-field="amountKrw"
        caption="KRW (EUR + USD)"
        dataType="number"
        format="###,###,###,##0.00"
        :header-filter="{ allowSearch: true }"
        :allow-editing="false"
        :editor-options="{ format: '###,###,###,##0.00' }"
      >
      </DxColumn>

      <!-- <DxColumn
        data-field="summaryFlag"
        caption="Summary"
        :header-filter="{ allowSearch: true }"
        :allow-editing="false"
      >
      </DxColumn> -->

      <DxMasterDetail :enabled="true" template="master-detail" />
      <template #master-detail="{ data }">
        <History
          :master-detail-data="data"
          :url="`/api/URUA/selectHistory`"
          :keyName="date"
          :columns="historyColumns"
        />
      </template>
    </DxDataGrid>
  </div>
</template>

<script>
import {
  DxDataGrid,
  DxColumn,
  DxScrolling,
  DxSorting,
  DxExport,
  DxHeaderFilter,
  DxEditing,
  DxSearchPanel,
  DxRequiredRule,
  DxMasterDetail,
} from "devextreme-vue/data-grid";

import History from "../_common/SIMPLE_HISTORY";
import dsu from "../../utils/dataSourceUtils";

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxScrolling,
    DxSorting,
    DxExport,
    DxHeaderFilter,
    DxEditing,
    DxSearchPanel,
    DxRequiredRule,
    DxMasterDetail,
    History,
  },
  data() {
    return {
      gridRefName: "grid",
      dataSource: dsu.getComDSGrid("/api/URUA", "dt", "", "Date"),
      historyColumns: [
        { dataField: "transTime", caption: "Update Time" },
        { dataField: "userId", caption: "Name" },
        { dataField: "amountEur", caption: "Amount EUR" },
        { dataField: "amountUsd", caption: "Amount USD" },
        { dataField: "dml", caption: "Action" },
      ],
    };
  },
  methods: {
    onExporting(e) {
      e.fileName = this.$route.meta.transName;
    },
  },
};
</script>

<style scoped>
#grid {
  max-height: calc(100vh - 140px);
}
</style>
